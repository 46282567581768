
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { GetFunnelsHtml } from "../../../Api/ReportClient";

export default function useReportFunnels() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    GetFunnelsHtml().then(response => {
        setData(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[])

  return { data, loading, error }
}