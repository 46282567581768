
import * as React from 'react';

import Stack from '@mui/material/Stack';
import { useCallback, useState, useEffect, useMatch } from "react";
import Button from '@mui/material/Button';

import TaskView from "./TaskView";
import toast from 'react-hot-toast';

import IterationStore from "./store/IterationStore";
import { useParams } from 'react-router-dom';

import useIterationGet from "./hooks/useIterationGet";
import useIterationEdit from "./hooks/useIterationEdit";

import defaultState from "./store/DefaultState";
import DottedSpinner from "../../../components/Core/DottedSpinner/DottedSpinner";

import Comments from "../../ChatApp/Chat/Comments";
import { ExportMeta } from "../Api/IterationClient";
import LoadingControl from '../../../components/Core/LoadingControl/LoadingControl';

export default function Iteration() 
{
  const { iterationID, projectID } = useParams();
  const [data, setData] = useState(defaultState);
  const [loading, setLoading] = useState(false);
 

  let store = new IterationStore(data, setData);
  
  const { iterationEdit, loadingEdit } = useIterationEdit(data, projectID, iterationID);
  const { iterationGet } = useIterationGet(iterationID);

  useEffect(()=>{
    setLoading(true);

      iterationID && iterationGet().then(result => { 
        result && setData(result);
        setLoading(false);
      });
 
},[iterationID, iterationGet]);

  //deleteItem={store.DeleteItem(task.id)}



  const Export = () =>{

    ExportMeta(iterationID).then(result => {

      var binary_string = window.atob(result.data.Data);
      var bytes = new Uint8Array(binary_string.length);
      for(var i = 0;i<binary_string.length; i++)
        bytes[i] = binary_string.charCodeAt(i);
      var blob = new Blob([bytes],{ type: 'application/pdf' });
      var url = URL.createObjectURL(blob);
      window.open(url);
    });


   
  }



  return (
    <div  className="m-5 w-full">
      <div class="space-y-1">
        <h2>Iteration Name</h2>


        {loading ? <><LoadingControl /></> :<>

        <button type="button" onClick={(e) => Export()} class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Export</button>
        <button type="button" onClick={(e) => iterationEdit()} class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
          {loadingEdit ? <DottedSpinner /> : <>Save</>}
        </button>
      
        {data.Tasks.filter(t => t.RecordState === 0).map((task) => (
         <TaskView key={task.Id} task={task}  store={store} />
        ))}

        <Button onClick={(e) => store.AddItem()}  style={{  backgroundColor: "#21b6ae" }} variant="contained">+ Line Item</Button>

     
          {/* 
        <Comments chatId={iterationID} />
      */}
        </>}
      </div>

    </div>
  );
}