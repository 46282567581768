import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userSearchText: "",
  showEditUserModal: false,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setUserSearchText: (state, { payload }) => {
      state.userSearchText = payload;
    },
    setShowEditUserModal: (state, { payload }) => {
      state.showEditUserModal = payload;
    },
  }
});

export const { setUserSearchText, setShowEditUserModal } = adminSlice.actions;

export default adminSlice;