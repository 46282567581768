

import { useCallback } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';

export default function useLogout() {

  const navigate = useNavigate();

  const logout = useCallback(async () => {

    ReactSession.set("user", null);
    //toast.success("Logged out");
    navigate("/auth/login");
    
  }, [navigate]);

  return logout;
}
