import axios from 'axios';
import Config from "../../../configurations/config"; 



export function GetData(appkey, platform)
{
  var timeframe = "weeks"
  var url = `https://s3.us-west-2.amazonaws.com/app.getproject/projects/${appkey}/funnels/${appkey}-${platform}-${timeframe}.json`
  console.log("url: " + url);
  return  axios.get(url);
}




export function GetExperimentList(projectId) 
{
    const apiPayload = { ProjectId : projectId };
    return Exec("experiments/all", apiPayload);
}

export function EditExperiment(model) 
{
  const apiPayload = { Model: model };
  return Exec("experiments/edit", apiPayload);
}

export function GetExperiment(experimentId)
{
  const apiPayload =  { ItemId: experimentId };
  return  Exec("experiments/get", apiPayload);
}

function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}