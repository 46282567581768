

import { useMatch, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';

import XChart from '../DashboardApp/components/XChart';

import useProjectGet from './hooks/useProjectGet';

import LoadingControl from '../../components/Core/LoadingControl/LoadingControl';
import InsightList from "../InsightApp/InsightList";
import FunnelListContainer from "./components/FunnelListContainer";

export default function Dashboard() {

  const match = useMatch("/project/:projectId/*");
  var projectId = match && match.params && match.params.projectId;
  const { project } = useProjectGet(projectId);

 

  return (
    <div className="m-5 w-full" >

      {!project ? <><LoadingControl /></>
      :
      <div>
    

      <XChart appKey={project.AppKey} appPlatform="ios" />

      <XChart appKey={project.AppKey} appPlatform="droid" />


      <FunnelListContainer projectId={projectId} />


     
      <div className="w-96">
       <InsightList project={project} />
      </div>





      </div>

      }

    </div>
  );
}