import React from 'react';


import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import { useCallback, useState, useEffect, useMatch } from "react";

import useChatSend from "./Hooks/useChatSend";
import { GetChat } from "./Api/ChatClient";
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '80vh'
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto'
  }
});

const Comments = ({ chatId }) => {

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [list, setList] = useState([]);

  useEffect(()=>{
    setLoading(true);
        chatId && GetChat(chatId).then(result => { 
        result && setList(result.data.Messages);
        setLoading(false);
      });
    },[chatId]);

  const handleOnBlur = e => {
    let attributeValue = e.target.value.trim();
    setMessage(attributeValue);
  }

  const handleSend =()=>{
    chatSend();
    // update ui / clear message
    var newList = 
    [
        ...list,
        {   
            Id: uuid().toString(), 
            Message: message, 
            Created: new Date().toLocaleDateString()
        }
    ];
    setList(newList);
    setMessage("");
    messageRef.current.value="";
  };


  const { chatSend } = useChatSend(chatId, message);
  const messageRef = React.createRef();

  return (
      <div>
        <Grid container>
            <Grid item xs={12}>
            </Grid>
        </Grid>
        <Grid container component={Paper} className={classes.chatSection}>
            <Grid item xs={12}>
            


                <List className={classes.messageArea}>
                    {list.map((item) => (

                    <ListItem key={item.Id}>
                        <Grid container>
                            <Grid item xs={12}>
                                <ListItemText align="right" primary={item.Message}></ListItemText>
                            </Grid>
                            <Grid item xs={12}>
                                <ListItemText align="right" secondary={item.Created}></ListItemText>
                            </Grid>
                        </Grid>
                    </ListItem>

                    ))}

                   
                   
                </List>

                <Divider />
                <Grid container style={{padding: '20px'}}>
                    <Grid item xs={11}>
                        <TextField inputRef={messageRef} onBlur={handleOnBlur} id="outlined-basic-email" label="Type Something" fullWidth />
                    </Grid>
                    <Grid xs={1} align="right">
                        <Fab color="primary" onClick={handleSend} aria-label="add"><SendIcon /></Fab>
                    </Grid>
                </Grid>
                
         
            </Grid>
        </Grid>
      </div>
  );
}

export default Comments;