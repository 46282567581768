
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetTicket } from "../Api/HelpDeskClient";


export default function useTicketGet(ticketId) {

  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {

    setLoading(true);


    if(!ticketId)
    {
      setTicket({
        Id: null,
        Name: "",
        Description: "",
        Status:0,
        RecordState:0,
        CreatedAt: Date.now()
      });
      setLoading(false);
      return;
    }

    GetTicket(ticketId).then(response => {
        setTicket(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[ticketId])

  return { ticket, setTicket, loading, error }
}
