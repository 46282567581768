import React from "react";
import { BarChart, Bar } from "recharts";
import useData from "../../Hooks/useData";

export default function RowAnalytics({ experiment }) {
  //---
  const appKey = experiment.AppKey;
  const platform = experiment.Platform;
  const metrics = experiment.Metrics;
  const { data } = useData(appKey, platform);

  //---
  var originalColumns = Object.getOwnPropertyNames(data);
  const rows = ["0", "1", "2", "3", "4", "5"]; //data ? Object.getOwnPropertyNames(data["year"]) : [];

  const GetConversion = (column, row) => {
    var currColIndex = metrics.indexOf(column);

    var nextColumn = metrics.at(currColIndex + 1);
    var previousColumn = metrics.at(currColIndex - 1);

    var nextValue = data.hasOwnProperty(nextColumn) ? data[nextColumn][row] : 0;
    var previousValue = data.hasOwnProperty(previousColumn)
      ? data[previousColumn][row]
      : 0;

    var conversion = nextValue / (previousValue / 100);
    return conversion;
  };

  var dataX = rows
    .slice(0, 10)
    .map((row) => {
      var result = { x: 0 };
      metrics.forEach((metric, i) => {
        if (metric.includes("%")) result = { x: GetConversion(metric, row) };
      });
      return result;
    })
    .reverse();

  const sum = dataX
    .map((item) => item.x)
    .reduce((partialSum, a) => partialSum + a, 0);

  const conversionValue = sum / dataX.length || 0;

  return (
    <div class="flex flex-row">
      <div class="flex-auto flex-col mx-3">
        <div class="font-medium text-sm text-center">Graph</div>
        <BarChart width={70} height={40} data={dataX}>
          <Bar dataKey="x" fill="#8884d8" />
        </BarChart>
      </div>

      <div class="flex-auto flex-col mx-3 my-auto">
        <div class="font-medium text-sm text-center">Conversion</div>
        <div class="text-gray-600 text-sm text-rose-800 text-center">
          {conversionValue.toFixed(2)} %
        </div>
      </div>
    </div>
  );
}
