import * as React from 'react';

import project_logo from "../Assets/Images/xcode_icon.png"; //".//./Assets/Images/item_icon.png"; //'/../Assets/Images/project_icon.png';
import Chip from '@mui/material/Chip';

import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import SalesItemView from '../../Project/Components/SalesItemView';



export default function ProjectRow({ project, logs }) {


  const navigate = useNavigate();
  const url = `/project/${project.Id}/`;

  const selectProject = () =>
  {

    navigate(url);
  };

  return (

      <li key={project.Id} class="flex flex-row">
        <div class="select-none hover:bg-gray-50 flex flex-1 items-center p-4">
          <div class="flex flex-col w-15 h-15 justify-center items-center mr-4">
            <img
              alt="profil"
              src={project.Logo ? project.Logo : project_logo}
              class="mx-auto object-cover rounded-md h-14 w-14"
            />
          </div>

          <div class="flex-1 pl-1">
            <div class="font-medium dark:text-white text-sm">
              <a href={url}>{project.Name}</a>
            </div>
            <div class="text-gray-600 dark:text-gray-200 text-sm">
              {project.Tags && project.Tags.map((tag) => <Chip label={tag} />)}
            </div>
          </div>

          <div class="pl-1">
            <div class="text-gray-600 dark:text-gray-200 text-sm">
             
            </div>
          </div>

          <div class="pl-1">
            <div class="text-gray-600 dark:text-gray-200 text-sm">
                <SalesItemView project={project} logs={logs} />
            </div>
          </div>
        </div>
      </li>

  );
}