
import * as React from 'react';
import { useState, useEffect } from "react";



import GroupRow from "./Components/GroupRow";
import { GetGroups } from "./Api/ResourceGroupClient"
import LoadingControl from '../Core/LoadingControl/LoadingControl';



export default function ProjectList() {



  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {

    setLoading(true);

    GetGroups().then(response => {
        setProjects(response.data);
        setLoading(false);
    });

    },[]);


  return (
    <div className="m-5 w-full">
      <a href="/groups/create" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Create</a>
      <div>
        {loading ? <><LoadingControl/></> :<>
        
   
        <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
        <ul class="flex flex-col divide-y w-full">
        {projects.map((group) => (
         <GroupRow group={group} key={group.Id} />
        ))}
        </ul>

        </div>
        </>}
      </div>
    </div>
  );
}