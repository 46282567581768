
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { GetForms } from "../Api/FormClient";


export default function useFormList(projectId) {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    GetForms(projectId).then(response => {
        setData(response.data);
        console.log(response.data);
        setLoading(false);
    });
  },[projectId])

  return { data, loading, error }
}