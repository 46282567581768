
import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { useCallback, useState, useEffect } from "react";

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';



export default function TaskView({ task, store }) {

    const handleChange = e => {
        //console.log("handle change => "+e.target.value);
        //const trimmedText = e.target.value.trim();
        //setText(trimmedText);
        //dispatch({ type: 'items/edit', payload: { itemId: itemId, itemText: trimmedText, fieldName: fieldName } });
      }


      const handleOnChange = e => {

        const taskId= task.Id;
        const attributeName = e.target.id;
        console.log(e.target.checked);
        let attributeValue = e.target.checked;
        console.log(attributeValue);

        console.log(taskId +" "+ attributeName +" "+ attributeValue);
        store.UpdateTask(taskId, attributeName, attributeValue);
        }

      
      const handleOnBlur = e => {
   
        const taskId= task.Id;
        const attributeName = e.target.id;
        let attributeValue = e.target.value.trim();

        if(attributeName === "Completed")
            attributeValue = e.target.checked;

      
        console.log(taskId +" "+ attributeName +" "+ attributeValue);

        store.UpdateTask(taskId, attributeName, attributeValue);
        //console.log(e.target.id);
        //dispatch({ type: 'items/edit', payload: { itemId: itemId, itemText: trimmedText, fieldName: fieldName } });
      }



  return (
    <Box>

      <Stack direction="row" spacing={2}>

      <input id="Completed" type="checkbox" onChange={handleOnChange} 
        defaultChecked={task.Completed}  class="self-center w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
    

        {/*
        <Checkbox color="success" id="Completed" onChange={handleOnChange} 
        checked={task.Completed} /> */}

        <input type="text" placeholder="Task Name" id="Name" onBlur={handleOnBlur} defaultValue={task.Name} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
        {/*<TextField fullWidth  id="Name" onBlur={handleOnBlur}
        defaultValue={task.Name} label="Task Name" variant="outlined" /> */}

        <input type="text" id="Hours" onBlur={handleOnBlur} defaultValue={task.Hours}  placeholder="Hours" class="w-14 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
        
        {/* 
        <input type="text" id="Cost" onBlur={handleOnBlur} defaultValue={task.Cost} placeholder="Cost" class="w-14 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
        */}
        
        
        <IconButton  onClick={(e) => store.DeleteItem(task.Id)} aria-label="delete">
           <DeleteIcon />
        </IconButton>
      </Stack>
    </Box>
  );
}