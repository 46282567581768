
import * as React from 'react';
import Box from '@mui/material/Box';

import Stack from '@mui/material/Stack';
import { useCallback, useState, useEffect } from "react";



import ItemView from "./Components/ItemView";
import useAppStoreList from "./Hooks/useAppStoreList";
  
import { useMatch, useNavigate } from "react-router-dom";
import LoadingControl from '../../components/Core/LoadingControl/LoadingControl';

export default function AppStoreList() 
{

  const {
    params: { projectId },
  } = useMatch("/project/:projectId/stores");

  const { data, loading } = useAppStoreList(projectId);


 
  var metrics =  [
    { Name:"Impressions", Value: "25900", Conversion: "0.0%" }, 
    { Name:"Units", Value: "1800", Conversion: "0.0%" },
    { Name:"Proceeds", Value: "706", Conversion: "0.0%" },
    { Name:"Sessions", Value: "2100", Conversion: "0.0%" },
    { Name:"Crashes", Value: "16", Conversion: "0.0%" } ];


  const GetFunnel = () =>
  {
    var last = null;
    metrics.forEach(metric => {
      if(last)
      {
        metric.Conversion = (metric.Value/(last.Value/100.0)).toFixed(2)+"%";
      }
      last = metric;
    });
    return metrics;
  }

  return (
    <Box>
      <Stack spacing={2}>
        <h2><b>OSHA ASO Analytics</b></h2>
        {
        GetFunnel().map((item) => (
            <h2>{item.Name} - {item.Value} - {item.Conversion}</h2>
        ))
        }


      

      <a 
      href={`/project/${projectId}/stores/create`}
      type="button" 
      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
        Create
      </a>




 
        {loading ? <><LoadingControl /></> :
        <>

        <div class="flex flex-col container  mt-10 mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
        <ul class="flex flex-col divide-y w-full">
        {data.map((store) => (
         <ItemView store={store} key={store.Id} />
        ))}
        </ul>
        </div>

        </>
        }
   
  


      </Stack>
    </Box>
  );
}