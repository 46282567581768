import * as React from "react";

export default function MetricList({ data }) {
  //const columns = ["dd", "dd", "ff"];
  var columns = Object.getOwnPropertyNames(data);

  return (
    <div class="mb-6">
      <label
        for="snapshots"
        class="block text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        Metrics
      </label>
      <span className="isolate inline-flex rounded-md shadow-sm">
        {columns.map((x) => {
          return (
            <button
              type="button"
              className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              {x}
            </button>
          );
        })}
      </span>
    </div>
  );
}
