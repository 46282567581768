
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetGroup } from "../Api/ResourceGroupClient";


export default function useGroupGet(groupId) {

  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {

    setLoading(true);

    if(!groupId)
    {
     const defaultState = {
            Id:"",
            Name:"",
            Tags:[],
            RecordState:0
          }
      setGroup(defaultState);
      setLoading(false);
      return;
    }

    GetGroup(groupId).then(response => {
        setGroup(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[groupId])

  return { group, setGroup, loading, error }
}
