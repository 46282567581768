
import * as React from 'react';

import ItemView from "./Components/ItemView";
import { useCallback, useState, useEffect } from "react";


import useReportList from "./Hooks/useReportList";
import { Route, Routes, useLocation, useNavigate, useMatch } from "react-router-dom";
import LoadingControl from '../../components/Core/LoadingControl/LoadingControl';




export default function ReportList() {





  const { data, loading } = useReportList();

  return (

      <div className="m-5">
        {loading ? <><LoadingControl /></> :
        <>

        <div>Reports - {data.length}</div>

     
        <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
        <ul class="flex flex-col divide-y w-full">
        {data.map((report) => (
         <ItemView report={report} key={report.Id} />
        ))}
        </ul>
        </div>

        </>}
      </div>

  );


}