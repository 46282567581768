
import { useCallback, useState, useMatch } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { EditInsight } from "../Api/InsightClient";

export default function useInsightEdit(formValues, projectId) {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const insightEdit = useCallback(async () => {
    try {

      setLoading(true);

      EditInsight(formValues.iterationId, formValues.name)
      .then(response => {

        const operationResponse = response.data;
        if(!operationResponse.Success)
        {
          toast.error(operationResponse.Message);
          return;
        }

        toast.success("Saved");
        navigate(`/project/${projectId}/iterations`);

      });

    } catch (e) {
      toast.error(e.message);
    } finally {

      setLoading(false);
    }
  }, [formValues, projectId, navigate]);

  return { insightEdit, loading };
}