

import Project from "./components/Project/Project";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

import { Toaster } from "react-hot-toast";

import Auth from "./components/Auth/Auth";
import Content from "./components/Content/Content";

import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

function App() {

  const currentUser = ReactSession.get("user"); 
  const atLogin = document.location.pathname.includes("/login");
  const navigate = useNavigate();

  useEffect(() => {
    if (!atLogin && !currentUser) {
      navigate("/auth/login");
    }
  }, [navigate, atLogin, currentUser]);

  
  return (
    <div className="wrapper">
      <div><Toaster/></div>
      
      <Routes>
        <Route path="/auth/*" element={<Auth />} />
        <Route path="/project/*" element={<Project />} />
        <Route path="/*" element={<Content />} />
      </Routes>

    </div>
  );
}

export default App;
