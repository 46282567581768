import * as React from "react";

export default function MetricTable({ experiment, data }) {
  var originalColumns = Object.getOwnPropertyNames(data);

  const metrics = experiment.Metrics ?? [];
  const rows = ["0", "1", "2", "3", "4", "5"]; //data ? Object.getOwnPropertyNames(data["year"]) : [];

  const GetConversion = (column, row) => {
    var currColIndex = metrics.indexOf(column);

    var nextColumn = metrics.at(currColIndex + 1);
    var previousColumn = metrics.at(currColIndex - 1);

    var nextValue = data.hasOwnProperty(nextColumn) ? data[nextColumn][row] : 0;
    var previousValue = data.hasOwnProperty(previousColumn)
      ? data[previousColumn][row]
      : 0;

    var conversion = nextValue / (previousValue / 100);
    return conversion.toFixed(2);
  };

  const GetValue = (column, row) => {
    if (data.hasOwnProperty(column)) return data[column][row];
    if (column.includes("%")) return GetConversion(column, row) + "%";
    return 0;
  };

  return (
    <div>
      <label
        for="header"
        class="block text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        {experiment.AppKey}-{experiment.Platform}
      </label>

      <table className="table-fixed">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className="font-normal">
            {metrics.map((metric) => (
              <>
                <th className="text-center">{metric}</th>
              </>
            ))}
            <th></th>
          </tr>
        </thead>

        <tbody>
          {rows.slice(0, 10).map((row) => (
            <tr
              key={row}
              className="border-b transition duration-300 ease-in-out hover:bg-neutral-100"
            >
              {metrics.map((metric) => (
                <>
                  <td
                    className={
                      metric.includes("%")
                        ? "text-center text-rose-800"
                        : "text-center text-gray-950"
                    }
                  >
                    {GetValue(metric, row)}
                  </td>
                </>
              ))}

              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
