
import * as React from 'react';

import { useCallback, useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, useMatch } from "react-router-dom";

import LoadingControl from '../../components/Core/LoadingControl/LoadingControl';
import ElementView from './Components/ElementView';
import useFormList from "./Hooks/useFormList";



export default function Form({ project }) {

  const { data, loading } = useFormList('');
  const form = data[0];


  return (<>
    {loading ? <LoadingControl /> :

    <div className='m-5 max-w-xl'>
    <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
    <ul class="flex flex-col divide-y w-full">
    {form?.Elements.map((el) => (
        <>
        <ElementView element={el}  />
    
        
        </>
    ))}
    </ul>
    </div>
    </div>

    }

    </>
  );
}