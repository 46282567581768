
import { useCallback, useState, useMatch } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { EditFunnel } from "../Api/FunnelClient";


export default function useFunnelSave(meta) {


  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);


  const funnelSave = useCallback(async () => {
    try {
      setSaving(true);

      const response = await EditFunnel(meta);
      const operationResponse = response.data;
      if(!operationResponse.Success)
      {
        toast.error(operationResponse.Message);
        return;
      }
      toast.success("Saved");

    } 
    catch (e) 
    {
      toast.error(e.message);
    } 
    finally 
    {
      setSaving(false);
    }

  }, [meta]);

  return { funnelSave, saving };
}