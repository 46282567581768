
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetExperimentList } from "../Api/ExperimentClient";

export default function useExperimentList(projectId) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    GetExperimentList(projectId).then(response => {
        var data = response.data;
        if(!data) data = [];
        //====
        setData(data);
        console.log(response.data);
        setLoading(false);
    });

  },[projectId])

  return { data, loading, error }
}