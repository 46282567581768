import { combineReducers } from "redux";
import accountSlice from "../redux/account/slice";
import adminSlice from "./admin/slice";
import projectSlice from "./project/slice";

export default combineReducers({
  account: accountSlice.reducer,
  admin: adminSlice.reducer,
  project: projectSlice.reducer

});
