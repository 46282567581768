import * as React from 'react';



import Modal from "../../Core/modal";


import { useState, useEffect } from 'react';

import TableView from "../../Core/Table/TableView";

export default function SalesItemView({ project, logs}) {
  
    const appKey = project.AppKey;
    const columns = (appKey && Object.getOwnPropertyNames(logs).filter(a => a.includes(appKey) || a ==="year" || a==="week")) ?? [];

    const [visible, setVisible ] = useState(false);
    var modelId = "output"+project.Id;
    
  
    const dialogAction = (show) =>{
      const targetEl = document.getElementById(modelId);
      const modal = new Modal(targetEl, null);
      if(show)
      {
          modal.show();
          setVisible(true);
      }
      else
      {
          modal.hide();
          setVisible(false);
      }
    };
  
    const show = (e) =>{
        console.log(columns);
        e.preventDefault();
        dialogAction(true);
    };
  
    const hide = (e) =>{
        e.preventDefault();
        dialogAction(false);
    };

    const GetSales = () =>
    {
        const salesColumns = (columns && columns.filter(c => c !== "week" && c !== "year")) ?? [];
        return project.Sales ? project.Sales +"/w": " --- ";
        //return salesColumns.length === 0 ? "no sales": "good";
    }

    const GetButtonStyles = () =>
    {
        const greenClass = "focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800";
        const yellowClass = "text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 mr-2 mb-2";



        const salesColumns = (columns && columns.filter(c => c !== "week" && c !== "year")) ?? [];
        if(salesColumns.length === 0 && !project.Sales)
            return yellowClass;
        return greenClass;
    };


  return (
    <div>

    <button type="button" name="popupBtn" onClick={show} class={GetButtonStyles()}>
        {!logs ?<>Loading</>:<>{GetSales()}</>} 
    </button>


    <div id={modelId} tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
        <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                        {project.Name}
                    </h3>
                    <button type="button" onClick={hide} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
              
                <div class="p-6 space-y-6 overflow-auto">
                    {!logs ?<>Loading</>:<TableView data={logs} appkey={project.AppKey}  />} 
                </div>
        
                <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                    <button onClick={hide} data-modal-toggle="defaultModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Close</button>
                </div>
            </div>
        </div>
    </div>

    </div>
  );
}