import * as React from "react";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import ProjectEdit from "../Project/ProjectEdit";
import ProjectList from "../Project/ProjectList";

import ResourceGroupList from "../ResourceGroup/ResourceGroupList";
import ResourceGroupEdit from "../ResourceGroup/ResourceGroupEdit";

import InsightList from "../../apps/InsightApp/InsightList";
import InsightEdit from "../../apps/InsightApp/InsightEdit";

import GroupProjectList from "../ResourceGroup/GroupProjectList";

import ReportList from "../../apps/ReportApp/ReportList";
import ReportSales from "../../apps/ReportApp/Reports/SalesReport/ReportSales";
import FunnelsReport from "../../apps/ReportApp/Reports/FunnelsReport/FunnelsReport";
import FunnelsV2Report from "../../apps/ReportApp/Reports/FunnelsV2Report/FunnelsV2Report";
import ProductSummariesReport from "../../apps/ReportApp/Reports/ProductSummariesReport/ProductSummariesReport";

import TicketList from "../../apps/HelpDesk/TicketList";
import TicketEdit from "../../apps/HelpDesk/TicketEdit";

import IterationMetaList from "../../apps/IterationApp/IterationMeta/IterationMetaList";

import Sidebar from "../Core/Sidebar/Sidebar";
import FunnelsWeekly from "../../apps/ReportApp/Reports/FunnelsReport/FunnelsWeekly";

export default function PermanentDrawerLeft() {
  var menuItems = [
    { name: "Insights", key: "insights", url: "/insights" },
    { name: "######", key: "seperator", url: "#" },
    { name: "Projects", key: "projects", url: "/projects" },
    { name: "Resource Groups", key: "groups", url: "/groups" },
    { name: "Iterations", key: "iterations", url: "/iterations" },
    { name: "HelpDesk", key: "helpdesk", url: "/helpdesk" },
    { name: "Reports", key: "reports", url: "/reports" },
    { name: "######", key: "seperator", url: "#" },
    { name: "Log out", key: "logout", url: "/auth/login" },
  ];

  var coreItems = [{ name: "Log out", key: "logout", url: "/auth/login" }];

  return (
    <div class="flex min-h-screen flex-row bg-gray-100 text-gray-800">
      <Sidebar menuItems={menuItems} coreItems={coreItems} />

      <main class="main -ml-64 flex flex-grow flex-col p-4 transition-all duration-150 ease-in md:ml-0">
        <div class="flex h-full bg-white shadow-md">
          <Routes className="h-100">
            {/* ------------ */}
            <Route path="/" element={<ResourceGroupList />} />
            <Route path="/projects" element={<ProjectList />} />
            <Route path="/projects/create" element={<ProjectEdit />} />
            {/* ------------ */}
            <Route path="/groups" element={<ResourceGroupList />} />
            <Route path="/groups/create" element={<ResourceGroupEdit />} />
            <Route
              path="/group/:groupId/edit"
              element={<ResourceGroupEdit />}
            />
            <Route path="/group/:groupId" element={<GroupProjectList />} />
            {/* ------------ */}
            <Route path="/iterations" element={<IterationMetaList />} />
            {/* ------------ */}
            <Route path="/insights" element={<InsightList />} />
            <Route path="/insights/create" element={<InsightEdit />} />
            {/* ------------ */}
            <Route path="/reports" element={<ReportList />} />
            <Route path="/reports/sales" element={<ReportSales />} />
            <Route path="/reports/funnels" element={<FunnelsReport />} />
            <Route path="/reports/funnels-v2" element={<FunnelsV2Report />} />
            <Route path="/reports/funnels-weekly" element={<FunnelsWeekly />} />
            <Route
              path="/reports/product-summaries"
              element={<ProductSummariesReport />}
            />
            {/* ------------ */}
            <Route path="/helpdesk" element={<TicketList />} />
            <Route path="/helpdesk/create" element={<TicketEdit />} />
            <Route path="/helpdesk/ticket/:ticketId" element={<TicketEdit />} />
          </Routes>
        </div>
      </main>
    </div>
  );
}
