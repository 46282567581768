import * as React from 'react';
import logo from "../Assets/Images/logo.png"; //'/../Assets/Images/project_icon.png';



export default function SnapshotView({ item }) {


  return (
    <li class="flex flex-row">
    <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
      <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
        <a  href={`/project/123/experiments/edit/123`} class="block relative">
          <img alt="profil" src={logo} class="mx-auto object-cover rounded-full h-10 w-10" />
        </a>
      </div>

      <div class="flex-1 pl-1">
        <div class="font-medium dark:text-white text-sm">Snapshot</div>
        <div class="text-gray-600 dark:text-gray-200 text-sm">Snapshot</div>
      </div>
  
      <div class="flex-1 pl-1">
        <div class="font-medium dark:text-white text-sm">Status</div>
        <div class="text-gray-600 dark:text-gray-200 text-sm">{item.Status}</div>
      </div>

      <div class="flex-1 pl-1">
        <div class="font-medium dark:text-white text-sm">CreatedAt</div>
        <div class="text-gray-600 dark:text-gray-200 text-sm">{item.CreatedAt}</div>
      </div>
    </div>
  </li>
  );

}