import axios from 'axios';
import Config from "../../../configurations/config"; //"../../configurations/config";




export function GetInsights(appKey) 
{
    const apiPayload = { AppKey: appKey };
    return Exec("insights/all", apiPayload);
}



export function EditInsight(insightId, name) 
{
  const apiPayload =  { Model: { Id: insightId,  Name: name} };
  return Exec( "insights/edit", apiPayload);
}



export function GetInsight({ insightId })
{
  const apiPayload =  { InsightId: insightId };
  return Exec("insights/get", apiPayload);
}




function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}
