import axios from 'axios';
import Config from "../../../configurations/config"; 

export function GetForms() 
{
    const apiPayload = { };
    return Exec("forms/all", apiPayload);
}


function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}