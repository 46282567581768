import React from "react";

import useSalesGet from "../hooks/useSalesGet";


import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Brush,
    AreaChart,
    Area,
    ResponsiveContainer,
  } from 'recharts';


export default function XChart({ appKey, appPlatform }) 
{

  var appName = appKey+"-"+appPlatform;

  const { data } = useSalesGet();


  const weeks = data && data["week"];

  console.log(data);
  //const labels = weeks && Object.keys(weeks).map((item, index) => data["year"][item]+"-"+weeks[item]).reverse();
  const metrics = appKey && weeks && Object.keys(weeks).map((item, index) => {
    var foundItems = Object.keys(data).filter(f => f === appName);
    return  { "week":data["week"][item], "sales": foundItems.length > 0  ? data[appName][item]:0 };
    }).reverse();



  return (
    <>
{ !metrics ? <>Loading</>:
<>

  <ResponsiveContainer width={100} height={50}>
          <AreaChart
            width={100}
            height={50}
            data={metrics}
            syncId="anyId"
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="2 2" />
            <Area type="monotone" dataKey="sales" stroke="#82ca9d" fill="#82ca9d" />
          </AreaChart>
    </ResponsiveContainer>

  

</>}
 
      
    </>
  );
}
