import React from "react";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";

export default function ChartView({ data }) {
  /*
  const data = [
    { week: 1, sales: 5 },
    { week: 2, sales: 7 },
    { week: 3, sales: 9 },
  ];
  */

  return (
    <>
      {!data ? (
        <>Loading</>
      ) : (
        <>
          <div className="">
            <ResponsiveContainer width={400} height={200}>
              <AreaChart
                width={400}
                height={200}
                data={data}
                syncId="anyId"
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="week" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="sales"
                  stroke="#82ca9d"
                  fill="#82ca9d"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </>
  );
}
