



import React, { useState, useEffect } from 'react';
import { useMatch, useNavigate } from "react-router-dom";

import { GetAppStore, EditAppStore } from "./Api/AppStoreClient"
import useAppStoreGet from "./Hooks/useAppStoreGet";

import toast from 'react-hot-toast';

export default  function AppStoreEdit() {

  const match = useMatch("/project/:projectId/*");
  var projectId = match && match.params && match.params.projectId;

  const { store, setStore, loading } = useAppStoreGet(projectId, null);
  const [saving, setSaving ] = useState(false);

  const handleOnBlur = e => {
    let elementName = e.target.name;
    let elementId = e.target.id;
    let attributeValue = e.target.value.toString().trim();

    if(elementId === "name") setStore({ ...store, Name: attributeValue });
  }



  const handleSubmit = event => {
    event.preventDefault();
    setSaving(true);
    const response = EditAppStore(store).then(response => {
      console.log(response.data);
      setSaving(false);
      toast.success("Saved");
    });
  };




  

  return (
 

<form  onSubmit={handleSubmit}  >

    <div class="mb-6">
        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
        <input 
            onBlur={handleOnBlur}
            defaultValue={store && store.Name}
            type="text" 
            id="name" 
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
            placeholder="Store Name" 
            required />
    </div> 

    <button 
            type="submit"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            Save
          </button>
    </form>


  );
}