
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";


export default function useReportList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    var data = [
        {Id:"1", Name:"Sales Report", Url:"/reports/sales" },
        {Id:"2", Name:"Funnels Report", Url:"/reports/funnels"},
        {Id:"3", Name:"Events Weekly", Url:"/reports/funnels-weekly"},
        {Id:"4", Name:"Product Summaries", Url:"/reports/product-summaries"},
        {Id:"5", Name:"Funnels Report v.2", Url:"/reports/funnels-v2"},
    ];

    setData(data);
    console.log(data);

    setLoading(false);

  },[])

  return { data, loading, error }
}
