import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const accountSlice = createSlice({
  name: "ACCOUNT",
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    setUnverifiedUser: (state, { payload }) => {
      state.unverifiedUser = payload;
    },
  }
});

export const { setUnverifiedUser, setCurrentUser } = accountSlice.actions;

export default accountSlice;