
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetGroups } from "../../ResourceGroup/Api/ResourceGroupClient"



export default function useGroupList() {
  const [groups, setGroups] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
      setLoading(true);
      setGroups(null);
      setError(null);

      GetGroups().then(response => {
        console.log(response);
        setGroups(response.data);
        setLoading(false);
    });

  },[])

  return { groups, loading, error }
}