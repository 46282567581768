
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


import React, { useState, useEffect } from 'react';
import { useMatch, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import DottedSpinner from "../../components/Core/DottedSpinner/DottedSpinner";

import useInsightEdit from "./Hooks/useInsightEdit";
import { GetInsight } from "./Api/InsightClient"


export default  function InsightEdit() {


  const insightId = null; // TODO HERE
  const [insight, setInsight] = React.useState(null);


  useEffect(() => {
    insightId && GetInsight(insightId).then( response => {
        setInsight(response);
    });
  },[insightId]);


  const form = useForm();
  const { register, handleSubmit, watch, formState } = form;
  const { errors } = formState;
  const formValues = watch();
  const { insightEdit, loading } = useInsightEdit(formValues);
  

  return (
    <Box sx={{ width: '500px' }}>

      <Stack component="form" onSubmit={handleSubmit(insightEdit)} spacing={2}>

          <TextField
            {...register("name", { required: "Name is required" })}
            required
            id="name"
            name="name"
            label="Insight Name"
            fullWidth
            variant="standard"  />

          <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
              {loading ? <DottedSpinner /> : <small>Save</small>}
          </Button>

      </Stack>

      
    </Box>
  );
}