
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";


import { GetInsights } from "../Api/InsightClient";

export default function useInsightsList(project) {
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  

  const appKey = project?.AppKey;

  useEffect(() => {

      setLoading(true);

      GetInsights(appKey).then(result => {

        setData(result.data);
        setLoading(false);

      });


  },[appKey])

  return { data, loading, error }
}