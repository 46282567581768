import axios from 'axios';
import Config from "../../../configurations/config"; //"../../configurations/config";


export function GetProjectsByGroup(groupId)
{
  var apiPayload = { };
  if(groupId && groupId.length > 0)
    apiPayload =  { GroupId: groupId };
  return  Exec("projects/all", apiPayload);
}

export function GetProjects() 
{
  var apiPayload = { };
  return Exec("projects/all", apiPayload);
}


export function EditProject(project) 
{
  const apiPayload = { Model: project };
  return Exec("projects/edit", apiPayload);
}


export function GetProject(projectId)
{
  const apiPayload =  { ProjectId: projectId };
  return  Exec("projects/get", apiPayload);
}


export function GetSignedUrl(fileKey)
{      
  const apiPayload =  { FileKey: fileKey };
  return  Exec("upload/signedurl", apiPayload);
}


export function GetSales()
{
  var timeframe = "weekly"
  var url = "https://s3.us-west-2.amazonaws.com/app.getproject/reports/sales/sales-weekly.json" 
  console.log("url: " + url);
  return axios.get(url);
}


function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}
