
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { GetFunnels } from "../../../Api/ReportClient";

export default function useFunnelsReport() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    GetFunnels().then(response => {
        setData(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[])

  return { data, loading, error }
}