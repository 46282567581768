import axios from 'axios';
import Config from "../../../configurations/config"; //"../../configurations/config";


export function GetGroups() 
{
    const apiPayload = { };
    return Exec("groups/all", apiPayload);
}

export function EditGroup(group) 
{
  const apiPayload = { Model: group };
  return Exec( "groups/edit", apiPayload);
}



export function GetGroup(groupId)
{
  const apiPayload =  { GroupId: groupId };
  return  Exec("groups/get", apiPayload);
}

export function GetProjectsByGroup(groupId)
{
  const apiPayload =  { GroupId: groupId };
  return  Exec("projects/all", apiPayload);
}


function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}




