

import * as React from 'react';


export default function AppsView({ apps, setApps}) {

    const [items, setItems] = React.useState(apps ?? []);

    const appList = [
        { Key:"funnels", Name:"funnels", Enabled: false }, 
        { Key:"experiments", Name:"experiments", Enabled: false },
        { Key:"iterations", Name:"iterations", Enabled: false},
        { Key:"insights", Name:"insights", Enabled: false},
        { Key:"helpdesk", Name:"helpdesk", Enabled: false},
        { Key:"storage", Name:"storage", Enabled: false},
        { Key:"forms", Name:"forms", Enabled: true},
    ];


    const onCheck = e => {
          //e.preventDefault();

      
          const itemKey = e.target.id;
          var foundItems = items.filter((item) => item === itemKey);
          if(e.target.checked)
          {
            if(foundItems.length > 0)
              return;

            const newItems = 
            [
                ...items,
                itemKey
            ];
            setItems(newItems);
            setApps && setApps(newItems);
          }
          else
          {
            if(foundItems.length === 0)
              return;

             const newItems = items.filter(item => item !== itemKey);
             setItems(newItems);
             setApps && setApps(newItems);
          }

          console.log(items);
    
           
         

         
        }

        const isChecked = (itemKey) =>{

          var foundItems = items.filter((item) => item === itemKey);
          return foundItems.length > 0;
        };
  

    return (
      <>

        <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
        <ul class="flex flex-col divide-y w-full">
        {appList.map((item) => (
          <div class=" selft-center flex items-center mb-4">
          <input id={item.Key} defaultChecked={isChecked(item.Key)} onClick={onCheck} type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
          <label for={item.Key} class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{item.Name}</label>
          </div>
        ))}
        </ul>
        </div>


    </>
    );
  
}

