import * as React from "react";
import logo from "./Assets/Images/logo.png";
import featured from "./Assets/Images/featured.png";

import { useState } from "react";

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  BeakerIcon,
  BugAntIcon,
} from "@heroicons/react/24/outline";

export default function ItemView({ item }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div onClick={() => setOpen(true)} class="block relative">
        <li key={item.Id} class="flex flex-row">
          <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
            <div class="flex flex-col w-15 h-15 justify-center items-center mr-4">
              <img
                alt="profil"
                src={item.Project?.Logo ?? logo}
                class="mx-auto object-cover rounded-md h-14 w-14"
              />
            </div>

            <div class="flex-1 pl-1">
              <div class="font-medium dark:text-white text-sm">
                <a href="#">{item.Project?.Name ?? item.AppKey}</a>
                <div>{item.Tag}</div>
              </div>
              <div class="text-sm">
                <div>App Insights</div>
              </div>
            </div>

            <div class="pl-1"></div>

            <div class="pl-1"></div>

            <div class="flex-none mx-3">
              <div class="text-center text-sm">
                <div className="text-red-600">
                  {item.Project?.Tags?.includes("product") ? (
                    <img className="w-8 h-8" src={featured} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div class="flex-none mx-3">
              <div class="text-sm font-medium">Priority</div>
              <div class="text-center text-sm">
                <div
                  className={
                    item.Priority === "critical"
                      ? "text-red-600"
                      : "text-yellow-600"
                  }
                >
                  {item.Priority}
                </div>
              </div>
            </div>

            <div class="flex-none mx-3">
              <div class="text-sm font-medium">Count</div>
              <div class="text-gray-600 text-center dark:text-gray-200 text-sm">
                {item.Messages.length}
              </div>
            </div>

            <div class="flex-none mx-3">
              <a
                href={"/project/" + item.Project?.Id}
                type="button"
                class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Open
              </a>
            </div>
          </div>
        </li>
      </div>

      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                  <ExclamationTriangleIcon
                    aria-hidden="true"
                    className="size-6 text-red-600"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold text-gray-900"
                  >
                    Insights
                  </DialogTitle>
                  <div className="mt-2">
                    {item.Messages.map((item) => (
                      <div className="flex text-black">
                        <div>
                          <BugAntIcon className="mx-2 h-5 w-5 text-red-500" />
                        </div>
                        <div>{item.Message}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  data-autofocus
                  onClick={() => setOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
