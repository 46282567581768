
import { useCallback, useState, useMatch } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Config from "../../../../configurations/config";

export default function useIterationGet(iterationID) {

  const [loading, setLoading] = useState(false);
  const iterationGet = useCallback(async () => {
    try {


      setLoading(true);

      // POST request using axios with async/await
      const requestBody = {
        Action: "iterations/get",
        Payload: { 
            MetaId: iterationID,
        },
      };
      
      const response = await axios.post(Config.serverUrl, requestBody);
      return response.data;
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return { iterationGet, loading };
}