
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetAppStore } from "../Api/AppStoreClient";


export default function useAppStoreGet(projectId, storeId) {

  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {

    setLoading(true);


    if(!storeId)
    {
     const defaultState = {
            Id:"",
            ProjectId: projectId,
            Name:"",
            RecordState:0
         }
      setStore(defaultState);
      setLoading(false);
      return;
    }

    GetAppStore(storeId).then(response => {
        setStore(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[storeId])

  return { store, setStore, loading, error }
}
