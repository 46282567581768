
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetFunnel } from "../Api/FunnelClient";
import defaultState from "../Store/DefaultState";

export default function useFunnelGet(projectId, funnelId) {

  const firstState = {
        ...defaultState,
        ProjectId: projectId,
      }

  const [meta, setMeta] = useState(firstState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {

    if(!funnelId)
    {
      setLoading(false);
      return;
    }

    setLoading(true);
    GetFunnel(projectId, funnelId).then(response => {
    
        setMeta(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[funnelId, projectId])

  return { meta, setMeta, loading, error }
}


