
import * as React from 'react';

import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { useCallback, useState, useEffect } from "react";


import defaultLogo from "../Assets/Images/default_logo.png";
import { GetSignedUrl } from "../Api/ListingClient";



export default function AppIconView({ listing, setListing }) {

    const inputRef = React.useRef(null);

    const handleClick = event => {
      if(event.target.files.length === 0)
        return;



        var file = event.target.files[0];
        
        var fileKey = `gp.projects/${listing.ProjectId}/images/logo.png`; 
        var pictureUrl =  `https://s3.us-west-2.amazonaws.com/app.getproject/${fileKey}`;
        
        listing && GetSignedUrl(fileKey).then(response => {
            var url = response.data.Data["url"];
            uploadUsingPresignedUrl(url, file).then(_ =>{

                setListing({ ...listing, AppIcon: pictureUrl });
            });
      });
    };

    const uploadUsingPresignedUrl = async (preSignedUrl, file) => {
        const getBlob = async (file) => new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
        fetch(preSignedUrl, {
            method: "PUT",
            body: await getBlob(file),
        });
    };

    const onClick = event => {
      inputRef.current.click();
    };
    
    return (
      <Box style={{cursor:"pointer"}}>
          <input ref={inputRef} type="file" style={{display: "none" }} onChange={handleClick}/>
          <CardMedia  
          style={{borderRadius: 8, width: "200px", height: "200px",  verticalAlign: "middle"}}  
          onClick={onClick} 
          component="img" 
          src={listing && listing.AppIcon ? listing.AppIcon : defaultLogo} />
      </Box>
    );
  
}