import * as React from 'react';
import logo from "../Assets/form.png"; //'/../Assets/Images/project_icon.png';


export default function ElementView({ element }) {




  return (


    <li key={element.Id} class="flex flex-row">
      <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
        <div class="flex flex-col w-15 h-15 justify-center items-center mr-4">
          <img
            alt="profil"
            src={logo}
            class="mx-auto object-cover rounded-md h-14 w-14"
          />
        </div>

        <div class="flex-1 pl-1">
       

          <div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{element.Text}</label>
            <input type="text" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
             placeholder={element.Placeholder} />
          </div>


        </div>

        <div class="pl-1">
        </div>

        <div class="pl-1">
        </div>

      </div>
    </li>



  );
}