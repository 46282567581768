
import * as React from 'react';


import { useCallback, useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, useMatch } from "react-router-dom";


import useFunnelList from "../../FunnelApp/Hooks/useFunnelList";
import useFunnelData from "../../FunnelApp/Hooks/useFunnelData";
import FunnelContainer from './FunnelContainer';

export default function FunnelListContainer({ projectId }) {


  const { list, loading } = useFunnelList(projectId);


  return (
    <div className='m-5 w-full'>

      <div className="flex flex-col">
        {loading ? <>Loading...</> :
        <>


        {list.map((funnel) => (
            <FunnelContainer funnel={funnel} />
        ))}
        

        </>}
      </div>
    </div>
  );


}