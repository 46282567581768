import * as React from "react";
import ItemList from "./ItemList";
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetSignedUrl, AddItem, EditItem, DeleteItem } from "./Api/StorageClient";
import useStorageGet from "./Hooks/useStorageGet";
import { useMatch, useNavigate } from "react-router-dom";

export default function Storage({ storageId }) {

  const match = useMatch("/project/:projectID/storage/:parentId");
  var parentId = match && match.params && match.params.parentId;

  const { items, setItems, loading, error } = useStorageGet(storageId, parentId);

  const onNewFolder = () => {

    var folderName = prompt("folder name");
    if(!folderName)
      return;

    var itemModel = { Id:generateQuickGuid(), Name:folderName,  Url:"", Type: "folder" };
    AddItem(storageId, itemModel).then((response) => {
      setItems([...items, itemModel]);
      toast.success("Folder has been added.");
    });

  };


  const onRename = (itemId) => {
    const foundItem = items.find(item => item.Id === itemId);
    // eslint-disable-next-line no-restricted-globals
    var newName = prompt("Please enter new name", foundItem.Name);
    if(!newName)
      return;

    const newItems = [...items]; // make a copy of the array
    const index = newItems.findIndex(item => item.Id === itemId); // find the index of the item to modify
    const newItem = { ...newItems[index], Name: newName }; // modify the item
    newItems[index] = newItem;
    setItems(newItems); // update the state with the modified array
  
    EditItem(storageId, newItem).then((response) => {
      toast.success("Item has been updated.");
    });
  };


  const onDelete = (itemId) => {
    // eslint-disable-next-line no-restricted-globals
    var confirmed = confirm("Please confirm to delete.");
    if(!confirmed)
      return;

    setItems(items.filter(item => item.Id !== itemId));
    DeleteItem(storageId, itemId).then((response) => {
      toast.success("Item has been deleted.");
    });
  };

  const onUpload = (event) => {
    if (event.target.files.length === 0) 
        return;

    let file = event.target.files[0];
    var itemModel = { Id:generateQuickGuid(), Name:file.name, ParentId: parentId,  Url:"" };
    console.log(itemModel);
    AddItem(storageId, itemModel).then((response) => {

      var uploadUrl = response.data.Data["upload_url"];
      itemModel.Url = response.data.Data["download_url"];
      // update ui
      setItems([...items, itemModel]);
      // uplopad
      uploadUsingPresignedUrl(uploadUrl, file).then((_) => {
        toast.success("Item has been uploaded.");
      }).catch(reason => {
        toast.error(reason);
      });
    });
  };



  const generateQuickGuid = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

 

  const uploadUsingPresignedUrl = async (preSignedUrl, file) => {
    const getBlob = async (file) => new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
    await fetch(preSignedUrl, {
      method: "PUT",
      body: await getBlob(file)
    });
  };

  

  if(!storageId)
    return (<div>NO STORAGE</div>);

  return (<div className="p-5 w-full">
    <button type="button" onClick={onNewFolder} class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">New Folder</button>


      <div class="flex items-center justify-center">

      
        <label
          for="dropzone-file"
          class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
          <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              aria-hidden="true"
              class="w-10 h-10 mb-3 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span class="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p class="text-xs text-gray-500 dark:text-gray-400">
              SVG, PNG, JPG or GIF (MAX. 800x400px)
            </p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            class="hidden"
            onChange={onUpload}
          />
        </label>
      </div>


      <ItemList onDelete={onDelete} onRename={onRename} loading={loading} items={items} />
    </div>);
}
