import Config from "../../../configurations/config";
import axios from "axios";

export function GetItemList(projectId) {
  const apiPayload = { ProjectId: projectId };
  return Exec("storage/items", apiPayload);
}

export function GetSignedUrl(fileKey) {
  const apiPayload = { FileKey: fileKey };
  return Exec("upload/signedurl", apiPayload);
}

export function GetStorage(storageId) {
  const apiPayload = { ItemId: storageId };
  return Exec("storages/get", apiPayload);
}

export function AddItem(storageId, itemModel) {
  const apiPayload = { StorageId: storageId, Model: itemModel };
  return Exec("storages/items/add", apiPayload);
}

export function EditItem(storageId, itemModel) {
  const apiPayload = { StorageId: storageId, Model: itemModel };
  return Exec("storages/items/edit", apiPayload);
}

export function DeleteItem(storageId, itemId) {
  const apiPayload = { StorageId: storageId, ItemId: itemId };
  return Exec("storages/items/delete", apiPayload);
}

function Exec(apiAction, apiPayload) {
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}
