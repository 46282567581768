
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetStorage } from "../Api/StorageClient";


export default function useStorageGet(storageId, parentId) {

  const [items, setItems] = useState([]);
  const [storage, setStorage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {

    setLoading(true);
    if(!storageId)
    {
      setLoading(false);
      return;
    }

    GetStorage(storageId).then(response => {

      //var parent = parentId === "root" ? null: parentId;
      console.log(response);
      var items = response.data.Items;
     
      if(!parentId || parentId === "root")
      {
        items = items.filter(item => item.ParentId === null || item.ParentId === "root");
        setItems(items);
        setLoading(false);
        return;
      }

    
      items = items.filter(item => item.ParentId === parentId);
      setItems(items);
      setLoading(false);

      

    }).catch((reason) => {
      console.log(reason);
      setLoading(false);
    });


  },[storageId, parentId])

  return { items, setItems, loading }
}