
import * as React from 'react';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { useCallback, useState, useEffect } from "react";


import defaultLogo from "../Assets/Images/default_logo.png";
import { GetSignedUrl } from "../Api/ProjectClient";
import toast from 'react-hot-toast';



export default function LogoView({ project, update }) {


    const inputRef = React.useRef(null);
    const [file, setFile] = useState(project && project.Logo ? project.Logo : defaultLogo);

 
    const handleClick = event => {

      if(event.target.files.length === 0)
      return;

      var file = event.target.files[0];

      setFile(URL.createObjectURL(file));


      var guid = generateQuickGuid();
      var fileKey = `gp.projects/${project.Id}/images/${guid}.png`; 
      var pictureUrl =  `https://s3.us-west-2.amazonaws.com/app.getproject/${fileKey}`;
      
      project && GetSignedUrl(fileKey).then(response => {
          var url = response.data.Data["url"];
          uploadUsingPresignedUrl(url, file).then(_ =>{

           
              update(pictureUrl);

            
          });
        });
      
    };


    const generateQuickGuid = () => {
      return Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15);
    };


    const uploadUsingPresignedUrl = async (preSignedUrl, file) => {
        const getBlob = async (file) => new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
        await fetch(preSignedUrl, {
            method: "PUT",
            body: await getBlob(file),
        });
    };

    const onClick = event => {
      inputRef.current.click();
    };


    return (
      <Box style={{cursor:"pointer"}}>
          <input ref={inputRef} type="file" style={{display: "none" }} onChange={handleClick}/>
          <CardMedia  
          style={{borderRadius: 8, width: "200px", height: "200px",  verticalAlign: "middle"}}  
          onClick={onClick} 
          component="img" 
          src={file}
          />
      </Box>
    );
  
}