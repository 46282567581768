
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetMeta } from "../../Api/IterationClient";


export default function useIterationMetaGet(metaId, projectId) {

  const [meta, setMeta] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {

    setLoading(true);
    if(!metaId)
    {
        setMeta({
        Id: metaId,
        ProjectId: projectId,
        Name: "",
        Description: "",
        Status:0,
        Type:0,
        
        Deadline: (new Date()).toJSON(),
        CreatedAt: (new Date()).toJSON(),

        RecordState:0
      });
      setLoading(false);
      return;
    }

    GetMeta(metaId).then(response => {
        setMeta(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[metaId, projectId])

  return { meta, setMeta, loading, error }
}
