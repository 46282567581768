import * as React from "react";
import { useCallback, useState, useEffect } from "react";

import useReportFunnels from "./Hooks/useReportFunnels";

import ReactHtmlParser from 'react-html-parser'; 
import LoadingControl from "../../../../components/Core/LoadingControl/LoadingControl";

export default function FunnelsReport() {


  const { data, loading } = useReportFunnels();

  return (
    <div className="m-5">
      <div>
        <h3>Funnels</h3>
        {loading ? (
          <><LoadingControl /></>
        ) : (
          <div>{ReactHtmlParser(data)}</div>
        )}
      </div>
    </div>
  );
}