
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetListing } from "../Api/ListingClient";


export default function useListingGet(projectId) {

  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {

    setLoading(true);
    GetListing(projectId).then(response => {
        
        var foundItem = response.data;
        if(!foundItem)
        {
            const defaultState = {
                Id: "",
                ProjectId: projectId,
                AppName: "",
                ShortDescription: "",
                FullDescription: "",
                AppIcon: null,
                FeatureGraphic: "",
                Video: "",
                Notes: "",
                Pictures: [],
                RecordState:0
              };
            foundItem = defaultState;
        }
        //========
        setListing(foundItem);
        console.log(foundItem);
        setLoading(false);
    });

  },[projectId])

  return { listing, setListing, loading, error }
}
