import axios from 'axios';
import Config from "../../../configurations/config"; 

export function GetTickets(projectId) 
{
    const apiPayload = { ProjectId : projectId, Status: 0 };
    return Exec("helpdesk/all", apiPayload);
}

export function GetTicket(ticketId) 
{
    const apiPayload = { TicketId : ticketId };
    return Exec("helpdesk/get", apiPayload);
}

export function EditTicket(ticket) 
{
  const apiPayload = { Model: ticket };
  return Exec("helpdesk/edit", apiPayload);
}

function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}