import * as React from "react";

import useFunnelData from "../../FunnelApp/Hooks/useFunnelData";

export default function FunnelContainer({ funnel }) {
  const appKey = funnel?.AppKey;
  const platform = funnel?.Platform;
  const { data } = useFunnelData(appKey, platform);

  if (!appKey || !platform) return <>No Records</>;

  var originalColumns = Object.getOwnPropertyNames(data);

  var metrics = [];
  if (funnel.Metrics && funnel.Metrics.length > 0) {
    metrics = funnel.Metrics.filter(
      (d) => d.RecordState !== 1 && originalColumns.some((c) => c === d.Name)
    );
  }

  var rules = [];
  if (funnel.Rules && funnel.Rules.length > 0) rules = funnel.Rules;

  var rows = [];
  if (metrics.length > 0)
    rows = Object.getOwnPropertyNames(data[metrics[0].Name]);

  const GetConversion = (row, column) => {
    var currentValue = data[column.Name][row];
    var currColIndex = metrics.indexOf(column);
    var previousColumn = metrics.at(currColIndex - 1);
    var previousValue = data[previousColumn.Name][row];
    var conversion = currentValue / (previousValue / 100);
    return conversion.toFixed(2);
  };

  return (
    <div className="my-5">
      <div className="font-medium">{funnel.Name}</div>

      <div>
        <table className="table-fixed">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr className="font-normal">
              {metrics.map((metric) => (
                <>
                  {metric.Conversion ? (
                    <th className="text-center">%</th>
                  ) : (
                    <></>
                  )}
                  <th className="text-center">{metric.Name}</th>
                </>
              ))}

              <th></th>
            </tr>
          </thead>

          <tbody>
            {rows.slice(0, 10).map((row) => (
              <tr key={row} class="bg-white border-b hover:bg-gray-50">
                {metrics.map((metric) => {
                  var cellValue = data[metric.Name][row];

                  var foundRule = rules.find((x) => x.Metric === metric.Name);
                  var cellStyle =
                    foundRule &&
                    parseInt(foundRule.Value) >= parseInt(cellValue)
                      ? "text-center bg-red-200"
                      : "text-center";

                  return (
                    <>
                      {metric.Conversion ? (
                        <td className="text-center">
                          {GetConversion(row, metric)}
                        </td>
                      ) : (
                        <></>
                      )}
                      <td className={cellStyle}>{cellValue}</td>
                    </>
                  );
                })}
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
