import * as React from "react";

import item_icon from "../Assets/Images/item_icon.png";
import Chip from "@mui/material/Chip";

export default function GroupView({ group }) {
  const url = `/group/${group.Id}`;

  return (
    <a href={url} class="block relative">
      <li key={group.Id} class="flex flex-row">
        <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
          <div class="flex flex-col w-15 h-15 justify-center items-center mr-4">
            <img
              src={item_icon}
              class="mx-auto object-cover rounded-md h-14 w-14"
            />
          </div>

          <div class="flex-1 pl-1">
            <div class="font-medium dark:text-white text-sm">
              <a href={url}>{group.Name}</a>
            </div>
            <div class="text-gray-600 dark:text-gray-200 text-sm">
              {group.Tags && group.Tags.map((tag) => <Chip label={tag} />)}
            </div>
          </div>

          <div class="pl-1">
            <div class="text-gray-600 dark:text-gray-200 text-sm"></div>
          </div>

          <div class="pl-1">
            <div class="text-gray-600 dark:text-gray-200 text-sm">
              <a
                href={url + "/edit"}
                type="button"
                class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Edit
              </a>
            </div>
          </div>
        </div>
      </li>
    </a>
  );
}
