
import { useCallback, useState, useEffect } from "react";
import { GetEvents } from "../../../Api/ReportClient";

export default function useProductEvents() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    GetEvents().then(response => {
        setData(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[])

  return { data, loading, error }
}