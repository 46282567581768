import React, { useState } from "react";

export default function MetricListEditor({ experiment }) {
  const qq = experiment.Metrics ?? ["metric1", "metric2"];

  const [items, setItems] = useState(qq);
  const [newItem, setNewItem] = useState("");

  // Add a new item to the list
  const addItem = () => {
    if (newItem.trim()) {
      const updatedItems = [...items, newItem];
      setItems(updatedItems);
      setNewItem(""); // Clear input after adding
      //--
      experiment.Metrics = updatedItems;
    }
  };

  // Remove an item from the list
  const removeItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    //--
    experiment.Metrics = updatedItems;
  };

  // Edit the content of an item
  const editItem = (index, updatedText) => {
    const updatedItems = items.map((item, i) =>
      i === index ? updatedText : item
    );
    setItems(updatedItems);

    //--
    experiment.Metrics = updatedItems;
  };

  return (
    <div class="mb-6">
      <label
        for="metrics-editor"
        class="block text-sm font-medium text-gray-900 dark:text-gray-300"
      >
        Metrics Editor
      </label>

      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        {/* List of items */}
        <ul className="list-none space-y-2">
          {items.map((item, index) => (
            <li
              key={index}
              className="flex justify-between items-center bg-gray-200 p-2 rounded"
            >
              {/* Editable span */}
              <span
                contentEditable
                suppressContentEditableWarning
                className="editable-item outline-none flex-1"
                onBlur={(e) => editItem(index, e.target.textContent)}
              >
                {item}
              </span>
              <button
                className="text-red-500 ml-2"
                onClick={() => removeItem(index)}
              >
                Remove
              </button>
            </li>
          ))}
        </ul>

        {/* Input and button for adding new item */}
        <div className="mt-4">
          <input
            type="text"
            className="border rounded p-2 w-full"
            value={newItem}
            placeholder="Add new item"
            onChange={(e) => setNewItem(e.target.value)}
          />
          <button
            onClick={addItem}
            className="mt-2 bg-blue-500 text-white py-2 px-4 rounded w-full"
          >
            Add Item
          </button>
        </div>
      </div>
    </div>
  );
}
