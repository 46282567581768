import * as React from "react";



import Stack from "@mui/material/Stack";

import ItemView from "./Components/ItemView";
import { useCallback, useState, useEffect } from "react";
import { GetProjects, GetProjectsByGroup } from "./Api/ProjectClient";

import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


import LoadingControl from "../Core/LoadingControl/LoadingControl";
import useReportSales from "./Hooks/useSalesGet";
import useGroupList from "./Hooks/useGroupList";

const sortArrayOfObjects = (arr, propertyName, order = 'ascending') => {
  const sortedArr = arr.sort((a, b) => {
    if (a[propertyName] < b[propertyName]) {
      return -1;
    }
    if (a[propertyName] > b[propertyName]) {
      return 1;
    }
    return 0;
  });

  if (order === 'descending') {
    return sortedArr.reverse();
  }

  return sortedArr;
};

export default function ProjectList() {

  const { data, downloading } = useReportSales();
  const { groups } = useGroupList();

  //==================
  const [group, setGroup] = useState('default');
  const [status, setStatus] = useState(0);
  const [text, setText] = useState("");
  //==================
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  //=================


  useEffect(() => {
    setLoading(true);


   
    var groupId = group === "default" ? '': group;
    GetProjectsByGroup(groupId).then((response) => {

      var items = response.data;
      items.sort(function(a, b)
      {
        var aSales = Number(a.Sales);
        var bSales = Number(b.Sales);
        //return aSales - bSales;
        return bSales - aSales;
      
      });

       // const items = sortArrayOfObjects(response.data, "Sales", 'descending');
       // console.log(items);


        setProjects(items);
        setLoading(false);
    });



  }, [group]);


  var timeout = null;
  const handleOnChange = (e) => {
    const searchValue = e.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setText(searchValue);
      console.log(searchValue);
      timeout = null;
    }, 500);
  };


  const handleChange = (e) => {

    var targetValue = e.target.value; 
    var targetName = e.target.name;

    if(targetName === "status")
      setStatus(targetValue);
   
    if(targetName === "group")
      setGroup(targetValue);

    console.log(e.target.name + " - " + targetValue);
  
  };


  return (
    <div className="m-5 w-full">
      <a
        href="/projects/create"
        type="button"
        class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      >
        Create
      </a>
      <Stack spacing={2}>

          <Stack direction="row">
              <TextField
                id="search"
                name="search"
                label="Search"
                onChange={handleOnChange}
                fullWidth
              />

              <Select
                style={{ marginLeft: "10px", width: "170px" }}
                id="group"
                name="group"
                defaultValue={group}
                onChange={handleChange}
                fullWidth
                label="Group">
                <MenuItem value={'default'}>All</MenuItem>
                {groups?.map((g) => (<MenuItem value={g.Id}>{g.Name}</MenuItem>))}
              </Select>

              <Select
                style={{ marginLeft: "10px", width: "170px" }}
                id="status"
                name="status"
                defaultValue={status}
                onChange={handleChange}
                fullWidth
                label="Status">
                <MenuItem value={0}>Open</MenuItem>
                <MenuItem value={1}>Closed</MenuItem>
              </Select>
          </Stack>





        {loading ? (
          <>
            <LoadingControl />
          </>
        ) : (
          <>
            <div class="flex flex-col container  mt-10 mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
              <ul class="flex flex-col divide-y w-full">
                {projects
                  .filter((p) => p.Status === status)
                  .filter((p) =>
                    text.length > 0
                      ? p.Name.toLowerCase().includes(text.toLowerCase())
                      : true
                  )
                  .map((project) => (
                    <ItemView project={project} key={project.Id} logs={data} />
                  ))}

               
              </ul>
            </div>

       


          </>
        )}
      </Stack>
    </div>
  );
}
