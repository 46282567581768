import * as React from "react";
import { useCallback, useState, useEffect } from "react";

import useFunnelsReport from "./Hooks/useFunnelsV2Report";
import TableView from "../../Components/TableView";
import LoadingControl from "../../../../components/Core/LoadingControl/LoadingControl";



export default function FunnelsWeekly() {

  const [appName, setAppName] = useState(null);
  const [platform, setPlatform] = useState(null);
  const { data, loading } = useFunnelsReport();
 

  var columns = Object.getOwnPropertyNames(data)
  var rows = Object.getOwnPropertyNames(data[columns[0]]);  

  const platformList = rows
  .map(row => data['platform'][row])
  .filter(function(item, pos, self) {
    return self.indexOf(item) === pos;
    });
  
  const appNames = rows
  .filter(row => !platform || platform === 'all' || data['platform'][row] === platform)
  .map(row => data['appkey'][row])
  .filter(function(item, pos, self) {
    return self.indexOf(item) === pos;
    });

  const selectAppName = (event) => {
    setAppName(event.target.value);
  }

  const selectPlatform = (event) => {
    console.log(event.target.value);
    setPlatform(event.target.value);
  }

  const filteredRows = rows.filter(r => (!appName || appName === "all" || data['appkey'][r] === appName) 
    && (!platform || platform === "all" || data['platform'][r] === platform));

  var filteredColumns = columns.filter(x => x !== 'index');
  filteredColumns.splice(filteredColumns.findIndex(el => el === 'year-week'), 1);
  filteredColumns.unshift('year-week');

  return (
    <div className="m-5">
      <div>

        <label for="countries"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Select platform
        </label>
        <select value={platform} onChange={selectPlatform}>
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option selected value="all">all platforms</option>
          {platformList.map(name => (<option value={name}>{name}</option>))}
        </select>



        <label for="countries"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Select an app
        </label>
        <select value={appName} onChange={selectAppName}>
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option selected value="all">all apps</option>
          {appNames.map(name => (<option value={name}>{name}</option>))}
        </select>


        
        <h3>Funnels Weekly</h3>
        {loading ? (
          <><LoadingControl /></>
        ) : (
          <>
            <TableView data={data}  rows={filteredRows} columns={filteredColumns} />
          </>
        )}
      </div>
    </div>
  );
}