
import * as React from 'react';

import ItemView from "./Components/ItemView";
import { useCallback, useState, useEffect } from "react";


import useItemList from "./Hooks/useItemList";
import { Route, Routes, useLocation, useNavigate, useMatch } from "react-router-dom";

import LoadingControl from '../../components/Core/LoadingControl/LoadingControl';


export default function ItemList({ items, loading, onDelete, onRename }) {

  const sortedItems = items.sort((a, b) => (a.Type === "folder" ? -1 : 1));


  return (

      <div className="m-5">
        {loading ? <><LoadingControl /></> :
        <>

        <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
        <ul class="flex flex-col divide-y w-full">
        {sortedItems.map((item) => (
         <ItemView item={item} onDelete={onDelete} onRename={onRename} key={item.Id} />
        ))}
        </ul>
        </div>

        </>}
      </div>
  );

}