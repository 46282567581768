

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


import { useMatch, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";

//===========
import AppIconView from "./Components/AppIconView";
import AppPictureView from "./Components/AppPictureView";
//===========

import { useHistory ,useLocation } from 'react-router-dom';

import useListingGet from "./Hooks/useListingGet";
import { EditListing } from "./Api/ListingClient";
import toast from 'react-hot-toast';
import LoadingControl from '../../components/Core/LoadingControl/LoadingControl';

export default function StoreListingEdit() {

  const match = useMatch("/project/:projectId/*");
  var projectId = match && match.params && match.params.projectId;
  const navigate = useNavigate();
  //=======================
  const { listing, setListing, loading } = useListingGet(projectId);


  const [saving, setSaving ] = useState(false);



  const handleOnBlur = e => {
    let elementId = e.target.id;
    let attributeValue = e.target.value.toString().trim();
    if(elementId === "app_name") setListing({ ...listing, AppName: attributeValue });
    if(elementId === "short_description") setListing({ ...listing, ShortDescription: attributeValue });
    if(elementId === "full_description") setListing({ ...listing, FullDescription: attributeValue });
    if(elementId === "app_icon") setListing({ ...listing, AppIcon: attributeValue });
    if(elementId === "feature_graphic") setListing({ ...listing, FeatureGraphic: attributeValue });
    if(elementId === "video") setListing({ ...listing, Video: attributeValue });
    if(elementId === "notes") setListing({ ...listing, Notes: attributeValue });
  }



  const handleSubmit = event => {
    event.preventDefault();
    setSaving(true);
    const response = EditListing(listing).then(response => {
      console.log(response.data);
      setSaving(false);
      toast.success("Saved");

    });
  };



  return (
    <div className="m-5" sx={{ width: '500px' }}>
    {!listing ? <><LoadingControl /></> :
      <Stack component="form" onSubmit={handleSubmit} spacing={2}>

          <div >
            <AppIconView listing={listing} setListing={setListing} />
          </div>

          <TextField
            id="app_name"
            name="app_name"
            label="App Name"
            onBlur={handleOnBlur}
            defaultValue={listing && listing.AppName}
            fullWidth
             />

          <TextField
            id="short_description"
            name="short_description"
            label="Short Description"
            onBlur={handleOnBlur}
            defaultValue={listing && listing.ShortDescription}
            fullWidth
             />

          <TextField
            id="full_description"
            name="full_description"
            label="Full Description"
            onBlur={handleOnBlur}
            defaultValue={listing && listing.FullDescription}
            fullWidth
            multiline
            rows={7}
             />

   

          <TextField
            id="feature_graphic"
            name="feature_graphic"
            label="Feature Graphic"
            onBlur={handleOnBlur}
            defaultValue={listing && listing.FeatureGraphic}
            fullWidth
             />



          <TextField
            id="video"
            name="video"
            label="Video"
            onBlur={handleOnBlur}
            defaultValue={listing && listing.Video}
            fullWidth
            />



          <Stack direction="row" spacing={2}>
            <AppPictureView fileName="1.png" listing={listing} setListing={setListing} />
            <AppPictureView fileName="2.png" listing={listing} setListing={setListing} />
            <AppPictureView fileName="3.png" listing={listing} setListing={setListing} />
            <AppPictureView fileName="4.png" listing={listing} setListing={setListing} />  
            <AppPictureView fileName="5.png" listing={listing} setListing={setListing} />  
          </Stack>



          <TextField
            id="notes"
            name="notes"
            label="Notes"
            onBlur={handleOnBlur}
            defaultValue={listing && listing.Notes}
            fullWidth
            multiline
            rows={7} />



          <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
              <small>Save</small>
          </Button>

      </Stack>
    }  

    </div>
  );
}