import React, { useState, useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import { GetExperiment, EditExperiment } from "./Api/ExperimentClient";
import useExperimentGet from "./Hooks/useExperimentGet";

import toast from "react-hot-toast";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import SnapshotView from "./Components/SnapshotView";
import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";

//--
import useData from "./Hooks/useData";
import { useHistory, useLocation } from "react-router-dom";

import MetricList from "./Components/MetricList/MetricList";
import MetricListEditor from "./Components/MetricListEditor/MetricListEditor";
import MetricTable from "./Components/MetricTable/MetricTable";

export default function ExperimentEdit() {
  //--

  const location = useLocation();
  const navigate = useNavigate();

  var template =
    location.pathname.indexOf("edit") > 0
      ? "/project/:projectId/experiment/:experimentId/edit"
      : "/project/:projectId/experiment/create";

  var match = useMatch(template);
  var projectId = match && match.params && match.params.projectId;
  var experimentId = match && match.params && match.params.experimentId;

  //===========
  const { experiment, setExperiment, loading } = useExperimentGet(
    projectId,
    experimentId
  );

  const { data } = useData(experiment?.AppKey, experiment?.Platform);

  const [saving, setSaving] = useState(false);
  //===========

  const handleOnBlur = (e) => {
    let elementName = e.target.name;
    let elementId = e.target.id;
    let attributeValue = e.target.value.toString().trim();

    if (elementId === "name")
      setExperiment({ ...experiment, Name: attributeValue });
    if (elementId === "notes")
      setExperiment({ ...experiment, Notes: attributeValue });
    if (elementName === "status")
      setExperiment({ ...experiment, Status: attributeValue });
    if (elementName === "type")
      setExperiment({ ...experiment, Type: attributeValue });

    //-----
    if (elementId === "appkey")
      setExperiment({ ...experiment, AppKey: attributeValue });
    if (elementId === "platform")
      setExperiment({ ...experiment, Platform: attributeValue });
    if (elementId === "keymetric")
      setExperiment({ ...experiment, KeyMetric: attributeValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSaving(true);
    const response = EditExperiment(experiment).then((response) => {
      console.log(response.data);
      setSaving(false);

      if (experimentId === "create") {
        navigate(`/project/${projectId}/experiments`);
        return;
      }
      toast.success("Saved");
    });
  };

  return (
    <div className="m-5 w-full">
      {loading ? (
        <>
          <LoadingControl />
        </>
      ) : (
        <>
          <div class="mb-6">
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Name
            </label>
            <input
              onBlur={handleOnBlur}
              defaultValue={experiment && experiment.Name}
              type="text"
              id="name"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Experiment Name"
              required
            />
          </div>

          <div class="mb-6">
            <Select
              id="status"
              name="status"
              defaultValue={experiment && experiment.Status}
              onBlur={handleOnBlur}
              label="Status"
            >
              <MenuItem value={0}>NotStarted</MenuItem>
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={2}>Pending</MenuItem>
              <MenuItem value={3}>Closed</MenuItem>
            </Select>
          </div>

          <div class="mb-6">
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              App Key
            </label>
            <input
              onBlur={handleOnBlur}
              defaultValue={experiment && experiment.AppKey}
              type="text"
              id="appkey"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="App Key"
              required
            />
          </div>

          <div class="mb-6">
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Platform
            </label>
            <input
              onBlur={handleOnBlur}
              defaultValue={experiment && experiment.Platform}
              type="text"
              id="platform"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Platform"
              required
            />
          </div>

          <div class="mb-6">
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Key Metric
            </label>
            <input
              onBlur={handleOnBlur}
              defaultValue={experiment && experiment.KeyMetric}
              type="text"
              id="keymetric"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Key Metric"
              required
            />
          </div>

          {/* 
          <div class="mb-6">
            <label
              for="notes"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Notes
            </label>
            <input
              onBlur={handleOnBlur}
              defaultValue={experiment && experiment.Notes}
              type="text"
              id="notes"
              name="notes"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Notes"
            />
          </div>
          */}

          {/* --- */}
          <MetricList data={data} />
          <MetricListEditor experiment={experiment} />
          <MetricTable experiment={experiment} data={data} />

          {/*
            <h4>{meta.AppKey}-{meta.Platform}</h4>
            <Stack direction="row" spacing={1}>
              {originalColumns.map((column) => (
              <Chip label={column} variant="outlined" />
            ))}
            </Stack>
 */}

          <div class="mb-6">
            <label
              for="snapshots"
              class="block text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Snapshots
            </label>
            <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
              <ul class="flex flex-col divide-y w-full">
                {experiment.Snapshots.map((snap) => (
                  <SnapshotView item={snap} key={snap.Id} />
                ))}
              </ul>
            </div>
          </div>

          <button
            onClick={handleSubmit}
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Save
          </button>
        </>
      )}
    </div>
  );
}
