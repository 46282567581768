
import { useCallback, useState, useMatch } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Config from "../../../../configurations/config";

export default function useIterationEdit(model, projectID, iterationID) {

  const navigate = useNavigate();
  const [loadingEdit, setLoadingEdit] = useState(false);

  const iterationEdit = useCallback(async () => {
    try {
      setLoadingEdit(true);

      console.log(projectID);
      console.log(iterationID);
      console.log(model);

      // POST request using axios with async/await
      const requestBody = {
        Action: "iterations/edit",
        Payload: { 
            MetaId: iterationID,
            ProjectId: projectID,
            Model: model
        },
      };

      const response = await axios.post(Config.serverUrl, requestBody);
      const operationResponse = response.data;
      if(!operationResponse.Success)
      {
        toast.error(operationResponse.Message);
        return;
      }
      toast.success("Saved");
     // navigate(`/project/${projectID}/iterations`);

    } catch (e) {

      toast.error(e.message);
    } finally {

      setLoadingEdit(false);
    }
  }, [model]);

  return { iterationEdit, loadingEdit };
}