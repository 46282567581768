import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetData } from "../Api/ExperimentClient";

export default function useData(appkey, platform) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    try {
      setLoading(true);
      appkey &&
        platform &&
        GetData(appkey, platform).then((response) => {
          setData(response.data);
          console.log(response.data);
          setLoading(false);
        });
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [appkey, platform]);

  return { data, loading, error };
}
