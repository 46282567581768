
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetTickets } from "../Api/HelpDeskClient";

export default function useTicketList(projectId) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);


    GetTickets(projectId).then(response => {

        setData(response.data);
        console.log(response.data);
        setLoading(false);
        
    });

  },[projectId])

  return { data, loading, error }
}