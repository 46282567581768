import * as React from "react";

import ItemView from "./Components/ItemView";
import useExperimentList from "./Hooks/useExperimentList";

import { useMatch, useNavigate } from "react-router-dom";
import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";
import useData from "./Hooks/useData";

export default function ExperimentList() {
  const {
    params: { projectId },
  } = useMatch("/project/:projectId/experiments");

  const { data, loading } = useExperimentList(projectId);

  return (
    <div className="m-5">
      <a
        href={`/project/${projectId}/experiment/create`}
        type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        Create
      </a>

      {loading ? (
        <>
          <LoadingControl />
        </>
      ) : (
        <>
          <ul class="flex flex-col divide-y">
            {data.map((item) => (
              <ItemView item={item} key={item.Id} />
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
