
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetAppStoreList } from "../Api/AppStoreClient";

export default function useFunnelList(projectId) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    GetAppStoreList(projectId).then(response => {
        setList(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[projectId])

  return { list, loading, error }
}
