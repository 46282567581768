import { v4 as uuid } from 'uuid';


export default class IterationStore {

    constructor(data, setData) {
      this.data = data;
      this.setData = setData;
    }


    UpdateTask(id, attributeName, attributeValue)
    {

      console.log("update Task");
      var newModel = {
        ...this.data,
        Tasks: this.data.Tasks.map(item => {
          if (item.Id !== id) {
            return item
          };

          if(attributeName === "Completed")
          return {
            ...item,
            Completed: attributeValue
          };

          if(attributeName === "Name")
          return {
            ...item,
            Name: attributeValue
          };

          if(attributeName === "Hours")
          return {
            ...item,
            Hours: attributeValue
          };

          if(attributeName === "Cost")
          return {
            ...item,
            Cost: attributeValue
          };

          return item;

        })
      };
      this.setData(newModel); 

      console.log(newModel);
    }


    AddItem()
    {
        console.log("add");
        console.log(this.data);

        var newModel = {
        ...this.data,
        Tasks: [
          ...this.data.Tasks,
          {
            Id: uuid(),
            Name: "",
            Completed: false,
            Hours: 0,
            RecordState: 0
          }
        ]
      }
      this.setData(newModel);
    };



    DeleteItem(id)
    {
        console.log("delete - "+id);
        console.log(this.data);

        var newModel = {
          ...this.data,
          Tasks: this.data.Tasks.map(item => {
            if (item.Id !== id) {
              return item
            }
            return {
              ...item,
              RecordState: 1
            }
          })
        };
        this.setData(newModel);  
    };
  
  }