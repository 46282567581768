import * as React from 'react';

import useLogout from "./hooks/useLogout";//"hooks/account/useLogout";

export default function Logout() {

  const tryLogout = useLogout();

  if(window.document.location.pathname === "/auth/logout")
  {
    console.log(window.document.location);
    tryLogout();
  }

  return (
<></>
  );
}