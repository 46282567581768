
import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';

import Login from "./Login/Login";
import Register from "./Register/Register";
import Logout from "./Logout/Logout";

export default function Content() {
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname === "/") navigate("/projects");

  return (
    <Stack>
        <Routes className="h-100">
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
     
              <Route path="logout" element={<Logout />} />
        </Routes>
    </Stack>

  );
}
