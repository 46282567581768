import * as React from "react";



import defaultProjectLogo from "../Assets/Images/xcode_icon.png"; 
import Chip from "@mui/material/Chip";

import SalesItemView from "./SalesItemView";

import XChartMini from "../../../apps/DashboardApp/components/XChartMini";


export default function ItemView({ project, key, logs }) {


  const url = `/project/${project.Id}/`;



  const onClick = (e) => {
    e.preventDefault();
    
    if(e.target.name === "popupBtn")
      return;

    window.location.href=url;
  }



  return (
    <a href={url}>
      <li key={key} class="flex flex-row">
        <div class="select-none hover:bg-gray-50 flex flex-1 items-center p-4">
          <div class="flex flex-col w-15 h-15 justify-center items-center mr-4">
            <img
              alt="profil"
              src={project.Logo ? project.Logo : defaultProjectLogo}
              class="mx-auto object-cover rounded-md h-14 w-14"
            />
          </div>

          <div class="flex-1 pl-1">
            <div class="font-medium dark:text-white text-sm">
             
              {project.Name}
          
            </div>
            <div class="text-gray-600 dark:text-gray-200 text-sm">
              {project.Tags && project.Tags.map((tag) => <Chip label={tag} />)}
            </div>
          </div>


          <div class="pl-1">
          {project.AppKey && project.AppKey !== '' ? <XChartMini appKey={project.AppKey} appPlatform="ios" /> :<></>}
          </div>
     
      

          <div class="pl-1">
            <div class="text-gray-600 dark:text-gray-200 text-sm">
              {project.Alert? 
              <button type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                Alert
              </button>
              :<></>}
            </div>
          </div>

     

          <div class="pl-1">
            <div class="text-gray-600 dark:text-gray-200 text-sm">
              <SalesItemView project={project} logs={logs} />
            </div>
          </div>



        </div>
      </li>
      </a>

  );
}
