import axios from 'axios';
import Config from "../../../configurations/config"; //"../../configurations/config";



export function GetFunnels()
{
    var url = "https://s3.us-west-2.amazonaws.com/app.getproject/reports/funnels-weekly.json" 
    console.log("url: " + url);
    return axios.get(url);
}


export function GetFunnelsHtml()
{
    var url = "https://s3.us-west-2.amazonaws.com/app.getproject/reports/funnels/funnels-weekly.html" 
    console.log("url: " + url);
    return axios.get(url);
}


export function GetProductSummariesHtml()
{
    var url = "https://s3.us-west-2.amazonaws.com/app.getproject/reports/product-summary-weekly.html" 
    console.log("url: " + url);
    return axios.get(url);
}


export function GetEvents()
{
    var url = "https://s3.us-west-2.amazonaws.com/app.getproject/reports/events-weekly.json" 
    console.log("url: " + url);
    return axios.get(url);
}

export function GetSales()
{
    var url = "https://s3.us-west-2.amazonaws.com/app.getproject/reports/sales/sales-weekly.json" 
    console.log("url: " + url);
    return axios.get(url);
}


function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}
