import * as React from "react";

import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useMatch,
} from "react-router-dom";

import useFunnelData from "./Hooks/useFunnelData";
import FunnelTable from "./Components/FunnelTable";
import FunnelEditor from "./Components/FunnelEditor";
import FunnelStore from "./Store/FunnelStore";
import useFunnelGet from "./Hooks/useFunnelGet";
import useFunnelSave from "./Hooks/useFunnelSave";
import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";

import { Funnel, Bar } from "funnel-react";

export default function FunnelEx() {
  const {
    params: { projectId, funnelId },
  } = useMatch("/project/:projectId/funnel/:funnelId");

  const { meta, setMeta, loading } = useFunnelGet(projectId, funnelId);

  const allKey = meta?.AppKey;
  const platform = meta?.Platform;
  const { data } = useFunnelData(allKey, platform);

  const { funnelSave, saving } = useFunnelSave(meta);

  let store = new FunnelStore(meta, setMeta);

  return (
    <div className="m-5">
      <button
        onClick={funnelSave}
        className="bg-white hover:bg-gray-100 text-gray-800 py-2 px-4 border border-gray-400 rounded shadow"
      >
        {saving ? <>Saving ...</> : <>Save</>}
      </button>

      <div className="m-2">
        {loading ? (
          <>
            <LoadingControl />
          </>
        ) : (
          <>
            <FunnelEditor meta={meta} store={store} />
          </>
        )}

        {loading ? (
          <>
            <LoadingControl />
          </>
        ) : (
          <>
            <FunnelTable
              showTags={true}
              save={funnelSave}
              meta={meta}
              data={data}
            />
          </>
        )}
      </div>
    </div>
  );
}
