
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { GetProject } from "../api/ProjectClient";

export default function useProjectGet(projectId) {
    
  const [project, setProject] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState("");


  useEffect(() => {
    setDownloading(true);

    GetProject(projectId).then(response => {
        setProject(response.data);
        console.log(response.data);
        setDownloading(false);
    });

  },[projectId]);

  return { project, downloading, error }
}