import * as React from "react";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

export default function FunnelTable({ meta, data, showTags }) {
  if (!meta) return <>No records</>;

  var originalColumns = Object.getOwnPropertyNames(data);

  var rules = [];
  if (meta.Rules && meta.Rules.length > 0) rules = meta.Rules;

  var metrics = [];
  if (meta.Metrics && meta.Metrics.length > 0) {
    metrics = meta.Metrics.filter(
      (d) => d.RecordState !== 1 && originalColumns.some((c) => c === d.Name)
    );
  }

  var rows = [];
  if (metrics.length > 0)
    rows = Object.getOwnPropertyNames(data[metrics[0].Name]);

  const GetConversion = (row, column) => {
    var currentValue = data[column.Name][row];
    var currColIndex = metrics.indexOf(column);
    var previousColumn = metrics.at(currColIndex - 1);
    var previousValue = data[previousColumn.Name][row];
    var conversion = currentValue / (previousValue / 100);
    return conversion.toFixed(2);
  };

  return (
    <div>
      <h4>
        {meta.AppKey}-{meta.Platform}
      </h4>
      {showTags ? (
        <Stack direction="row" spacing={1}>
          {originalColumns.map((column) => (
            <Chip label={column} variant="outlined" />
          ))}
        </Stack>
      ) : (
        <></>
      )}

      <table className="table-fixed">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr className="font-normal">
            {metrics.map((metric) => (
              <>
                {metric.Conversion ? <th className="text-center">%</th> : <></>}
                <th className="text-center">{metric.Name}</th>
              </>
            ))}
            <th></th>
          </tr>
        </thead>

        <tbody>
          {rows.slice(0, 10).map((row) => (
            <tr
              key={row}
              className="border-b transition duration-300 ease-in-out hover:bg-neutral-100"
            >
              {metrics.map((metric) => {
                var cellValue = data[metric.Name][row];

                var foundRule = rules.find((x) => x.Metric === metric.Name);
                var cellStyle =
                  foundRule && parseInt(foundRule.Value) >= parseInt(cellValue)
                    ? "text-center bg-red-200"
                    : "text-center";

                return (
                  <>
                    {metric.Conversion ? (
                      <td className={cellStyle}>
                        {GetConversion(row, metric)}
                      </td>
                    ) : (
                      <></>
                    )}
                    <td className={cellStyle}>{cellValue}</td>
                  </>
                );
              })}

              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
