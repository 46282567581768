import axios from 'axios';
import Config from "../../../configurations/config"; //"../../configurations/config";



export function EditListing(listingModel) 
{
  const apiPayload = { Model: listingModel };
  return Exec("listing/edit", apiPayload);
}

export function GetListing(projectId)
{
  const apiPayload =  { ProjectId: projectId };
  return  Exec("listing/get", apiPayload);
}


export function GetSignedUrl(fileKey)
{
  //var fileKey = `gp.projects/${projectId}/listing/images/logo.png`;         
  const apiPayload =  { FileKey: fileKey };
  return  Exec("upload/signedurl", apiPayload);
}

function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}
