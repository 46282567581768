
import * as React from 'react';

import ItemView from "./Components/ItemView";
import { useCallback, useState, useEffect } from "react";


import useFunnelList from "./Hooks/useFunnelList";
import { Route, Routes, useLocation, useNavigate, useMatch } from "react-router-dom";
import LoadingControl from '../../components/Core/LoadingControl/LoadingControl';



export default function FunnelList() {

  const {
    params: { appId, projectId },
  } = useMatch("/project/:projectId/:appId");


  //const [data, setData] = useState([]);
  //const [loading, setLoading] = useState(false);
  //const { data, loading, error } = useProjectEdit();
  const { list, loading } = useFunnelList(projectId);


  return (
    <div className='m-5 w-full'>
      <a href={`/project/${projectId}/funnels/create`} type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Create</a>

      <div>
        {loading ? <><LoadingControl /></> :
        <>

        {list.map((funnel) => (
         <ItemView funnel={funnel} key={funnel.Id} />
        ))}
        
        </>}
      </div>
    </div>
  );


}