import * as React from 'react';
import logo from "../Assets/Images/logo.png"; //'/../Assets/Images/project_icon.png';


/*
export default function ItemView({ funnel }) {
  const url = `/project/${funnel.ProjectId}/funnel/${funnel.Id}`;
  return (
    <Paper sx={{ p: 1, margin: '1' }}>
      <Grid container spacing={2}>
        <Grid item>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column">
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
                <a href={url}>{funnel.Name}</a>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ID: {funnel.Id}
              </Typography>
              <a href={url+"/edit"}>
                  edit
              </a>
            </Grid>
            <Grid item>
            <Stack direction="row" spacing={1}>
            </Stack>
            </Grid>
          </Grid>
          <Grid item>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
*/

export default function ItemView({ store }) {


  return (
    <li class="flex flex-row">
    <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
      <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
        <a href="#" class="block relative">
          <img alt="profil" src={logo} class="mx-auto object-cover rounded-full h-10 w-10" />
        </a>
      </div>

      <div class="flex-1 pl-1">
        <div class="font-medium dark:text-white text-sm">Store</div>
        <div class="text-gray-600 dark:text-gray-200 text-sm">{store.Name}</div>
      </div>
  
      <div class="flex-1 pl-1">
        <div class="font-medium dark:text-white text-sm">Metric</div>
        <div class="text-gray-600 dark:text-gray-200 text-sm">111</div>
      </div>

      <div class="flex-1 pl-1">
        <div class="font-medium dark:text-white text-sm">Metric</div>
        <div class="text-gray-600 dark:text-gray-200 text-sm">2222</div>
      </div>
    </div>
  </li>
  );

}