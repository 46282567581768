
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { GetSales } from "../Api/ProjectClient";

export default function useReportSales() {
    
  const [data, setData] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState("");


  useEffect(() => {
    setDownloading(true);

    GetSales().then(response => {
        setData(response.data);
        console.log(response.data);
        setDownloading(false);
    });

  },[]);

  return { data, downloading, error }
}

