
import * as React from 'react';

import { useCallback, useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate, useMatch } from "react-router-dom";

import useFormList from "./Hooks/useFormList";
import ItemView from "./Components/ItemView";
import LoadingControl from '../../components/Core/LoadingControl/LoadingControl';

export default function FormList({ project }) {

  const { data, loading } = useFormList('');

  return (
    <div className='m-5 max-w-xl'>
    <div>
      {loading ? <><LoadingControl /></> :
      <>
      <div>Forms - {data.length}</div>

      <div class="flex flex-col container mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
      <ul class="flex flex-col divide-y w-full">
      {data.map((form) => (
       <ItemView project={project} form={form} key={form.Id} />
      ))}
      </ul>
      </div>
      
      </>}
    </div>
  </div>
  );
}