import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetExperiment } from "../Api/ExperimentClient";

export default function useExperimentGet(projectId, experimentId) {
  const [experiment, setExperiment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    console.log(projectId + " " + experimentId);

    setLoading(true);
    if (!experimentId || experimentId === "new") {
      const defaultState = {
        Id: "",
        ProjectId: projectId,
        Name: "",
        //----
        AppKey: "",
        Platform: "",
        KeyMetric: "",
        //----
        Notes: "",
        Snapshots: [],
        Status: 0,
        RecordState: 0,
      };
      setExperiment(defaultState);
      setLoading(false);
      return;
    }

    GetExperiment(experimentId).then((response) => {
      setExperiment(response.data);
      console.log(response.data);
      setLoading(false);
    });
  }, [experimentId, projectId]);

  return { experiment, setExperiment, loading, error };
}
