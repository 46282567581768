import axios from 'axios';
import Config from "../../../configurations/config"; //"../../configurations/config";


export function GetProject(projectId)
{
  const apiPayload =  { ProjectId: projectId };
  return  Exec("projects/get", apiPayload);
}


export function GetSales()
{
  var timeframe = "weekly"
  var url = "https://s3.us-west-2.amazonaws.com/app.getproject/reports/sales/sales-weekly.json" 
  console.log("url: " + url);
  return axios.get(url);
}


function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}