
import * as React from 'react';

import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { useCallback, useState, useEffect } from "react";

import defaultLogo from "../Assets/Images/default_logo.png";
import { GetSignedUrl } from "../Api/ListingClient";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';


export default function AppPictureView({ fileName, listing, setListing }) {





    const inputRef = React.useRef(null);

    const handleClick = event => {
      if(event.target.files.length === 0)
        return;


        onDelete();

        var file = event.target.files[0];
        
        var timestamp = new Date().getTime();//Timestamp
        var fileKey = `gp.projects/${listing.ProjectId}/listing/images/${timestamp}.png`; 
        var pictureUrl =  `https://s3.us-west-2.amazonaws.com/app.getproject/${fileKey}`;

        listing && GetSignedUrl(fileKey).then(response => {

            var url = response.data.Data["url"];
            uploadUsingPresignedUrl(url, file).then(_ =>{

                var newPic = { Id: fileName, Url: pictureUrl };

                var picList = listing.Pictures;
                const found = picList.find(element => element.Id === fileName);
                if(!found) picList.push(newPic);
                const newPicList = picList.map(element => {
                  if (element.Id === fileName) 
                    return newPic;
                  return element;
                });

                setListing({ ...listing, Pictures: newPicList });

                console.log(listing);

            });
      });
    };

    const uploadUsingPresignedUrl = async (preSignedUrl, file) => {
        const getBlob = async (file) => new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
        await fetch(preSignedUrl, {
            method: "PUT",
            body: await getBlob(file),
        });
    };

    const onClick = event => {
      inputRef.current.click();
    };

    const onDelete = event => {

      var filtered = listing.Pictures.filter( d => d.Id !== fileName);
      setListing({ ...listing, Pictures: filtered });
     
    };

    const GetPic =()=> {
      if(!listing)
      return defaultLogo;

      const found = listing.Pictures.find(element => element.Id === fileName);
      if(!found)
        return defaultLogo;

      return found.Url;
    };


    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    return (
      <Box style={{cursor:"pointer"}}>
          <Button onClick={handleClickOpen}>Preview</Button><Button onClick={onDelete}>Delete</Button>
          <input ref={inputRef} type="file" style={{display: "none" }} onChange={handleClick}/>
          <CardMedia  
          style={{borderRadius: 8, width: "200px", height: "400px",  verticalAlign: "middle"}}  
          onClick={onClick} 
          component="img" 
          src={GetPic()} />

<Dialog 
     open={open}
     fullWidth
     onClose={handleClose}>


        <DialogContent>
      
          <CardMedia  
          style={{borderRadius: 8, width: "550px", height: "600px",  verticalAlign: "middle"}}  
     
          component="img" 
          src={GetPic()} />


       
        </DialogContent>
        <DialogActions>
    
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
</Dialog>


      </Box>
    );
  
}