import * as React from 'react';
import report_logo from "../Assets/report_icon.png"; 

export default function ItemView({ report }) {

  return (
    <a href={report.Url} class="block relative">
    <li key={report.Id} class="flex flex-row">
      <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-1">
        <div class="flex flex-col w-10 h-10 justify-center items-center mr-2">
          <img
            alt="profil"
            src={report_logo}
            class="mx-auto object-cover rounded-md h-14 w-14"
          />
        </div>

        <div class="flex-1 pl-1">
          <div class="font-medium dark:text-white text-sm">
            <a href={report.Url}>{report.Name}</a>
          </div>
        </div>

        <div class="pl-1">
        </div>

        <div class="pl-1">
        </div>

      </div>
    </li>
  </a>);
}