import axios from 'axios';
import Config from "../../../configurations/config"; //"../../configurations/config";

export function GetAppStoreList(projectId) 
{
    const apiPayload = { ProjectId : projectId };
    return Exec("appstores/all", apiPayload);
}

export function EditAppStore(model) 
{
  const apiPayload = { Model: model };
  return Exec("appstores/edit", apiPayload);
}

export function GetAppStore(appStoreId)
{
  const apiPayload =  { ItemId: appStoreId };
  return  Exec("appstores/get", apiPayload);
}

function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}
