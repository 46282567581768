
import { v4 as uuid } from 'uuid';


const DefaultState = {
    Id: null,
    MetaId: null,
    ProjectId: null,
    Name: "Funnel",
    Metrics :[
      { Id: uuid(), Name: "", Enabled: true, Conversion: false, RecordState: 0 },
      { Id: uuid(), Name: "", Enabled: true, Conversion: false, RecordState: 0 },
      { Id: uuid(), Name: "", Enabled: true, Conversion: false, RecordState: 0 },
    ],
    Rules :[
        { Id: uuid(), Column: "", Enabled: true, Value: 0, Conversion: 0.0, RecordState: 0 },
        { Id: uuid(), Column: "", Enabled: true, Value: 0, Conversion: 0.0, RecordState: 0 },
        { Id: uuid(), Column: "", Enabled: true, Value: 0, Conversion: 0.0, RecordState: 0 },
      ]
  };


  export default DefaultState;