import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';


export default function TableView({ data, appkey }) {

  const columns = (appkey && Object.getOwnPropertyNames(data).filter(a => a.includes(appkey) || a === "year" || a === "week")) ?? [];
  const rows = (columns.length > 0 && Object.getOwnPropertyNames(data[columns[0]])) ?? [];

  return (
    <Box>


    <TableContainer component={Paper}>
      <Table  sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
          {columns && columns.map((column) => (
            <TableCell align="left"><b>{column}</b></TableCell>
          ))}
          <TableCell>
          </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows && rows.map((row) => (
            <TableRow
              key={row}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

              {columns.map((column) => (
                <TableCell width={150} align="left">
                  {data[column][row]}
                </TableCell>
              ))}
              
              <TableCell>
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </Box>
  );
}