import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  project: null
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setRexuxProject: (state, { payload }) => {
      state.project = payload;
    },
  }
});

export const { setRexuxProject } = projectSlice.actions;

export default projectSlice;