

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useMatch, useNavigate, useLocation  } from "react-router-dom";
import React, { useState, useEffect } from 'react';

import { useForm } from "react-hook-form";
import DottedSpinner from "../../../components/Core/DottedSpinner/DottedSpinner"


import useIterationMetaEdit from "./hooks/useIterationMetaEdit";
import useIterationMetaGet from "./hooks/useIterationMetaGet";
import { EditMeta } from "../Api/IterationClient";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';



import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import LoadingControl from '../../../components/Core/LoadingControl/LoadingControl';



export default function IterationMetaEdit({ project }) {


  const location = useLocation();
  var template =location.pathname.indexOf("edit") > 0
  ? "/project/:projectId/iterations/:iterationId/edit" : "/project/:projectId/iterations/create";
  const match = useMatch(template);

  var projectId = match && match.params && match.params.projectId;
  var metaId = match && match.params && match.params.iterationId;
  //===============


  const navigate = useNavigate();
  const { meta, setMeta, loading, } = useIterationMetaGet(metaId, projectId);
  const [saving, setSaving ] = useState(false);
  //const { iterationMetaEdit, loading } = useIterationMetaEdit(formValues, projectId);



const handleOnBlur = e => {
  
  console.log(e);
  let elementName = e.target.name;
  let attributeValue = e.target.value.toString().trim();
  let elementId = e.target.id;
  //---
  if(elementId === "name") setMeta({ ...meta, Name: attributeValue });
  if(elementId === "cost") setMeta({ ...meta, Cost: attributeValue });
  if(elementName === "status") setMeta({ ...meta, Status: attributeValue });
  if(elementName === "type") setMeta({ ...meta, Type: attributeValue });

}

const handleChange = (newValue) => {
  setMeta({ ...meta, Deadline: newValue });
};


const handleSubmit = event => {
  event.preventDefault();
  setSaving(true);

  var metaEx = { ...meta, Project: project };
  const response = EditMeta(metaEx).then(response => {
    console.log("SAVED");
    console.log(metaEx);
    setSaving(false);
    navigate(`/project/${projectId}/iterations`);
  });
}


const handleDelete = event => {
  event.preventDefault();

  var result = window.confirm("Please confirm to delete.");
  if(!result)
    return;
  var metaEx = { ...meta, RecordState: 1 };

  setSaving(true);
  const response = EditMeta(metaEx).then(response => {
    console.log(response.data);
    setSaving(false);
    navigate(`/project/${projectId}/iterations`);
  });
}


  return (
    <div className="m-5 w-full">{loading ? <><LoadingControl /></> :<>
      <Stack component="form" onSubmit={handleSubmit} spacing={2}>
        <TextField
            required
            id="name"
            name="name"
            label="Name"
            defaultValue={meta && meta.Name}
            onBlur={handleOnBlur}
            fullWidth
           />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
          label="Deadline"
          id="deadline"
          name="deadline"
          inputFormat="MM/DD/YYYY"
          value = {meta && meta.Deadline}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>


          <Select
              id="status"
              name="status"
              defaultValue={meta && meta.Status}
              onBlur={handleOnBlur}
              label="Status">
              <MenuItem value={0}>NotStarted</MenuItem>
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={2}>Pending</MenuItem>
              <MenuItem value={3}>Closed</MenuItem>
          </Select>


          <Select
              id="type"
              name="type"
              defaultValue={meta && meta.Type}
              onBlur={handleOnBlur}
              label="Type">
              <MenuItem value={0}>Revenue</MenuItem>
              <MenuItem value={1}>Expense</MenuItem>
          </Select>
   

          <TextField
            required
            id="cost"
            name="cost"
            label="Cost"
   
            defaultValue={meta && meta.Cost}
            onBlur={handleOnBlur}
            fullWidth
           />



     


          <Stack direction="row" style={{  marginTop: "50px" }}  spacing={2}>

          <Button
              onClick={handleDelete}
              fullWidth
              variant="contained">
              Delete
          </Button>

          <Button
              type="submit"
              fullWidth
              variant="contained" >
                {saving ? <DottedSpinner /> : <>Save</>}
          </Button>

          </Stack>


      </Stack>
      </>}
      </div>
  );
}