import * as React from 'react';
import file_logo from "../Assets/file_icon.png"; 
import folder_logo from "../Assets/folder_icon.png"; 

export default function ItemView({ item, onDelete, onRename }) {



  return (
    <a href={item.Type === "folder" ? window.location.pathname.replace("root", item.Id) : item.Url} target="_blank" class="block relative">
    <li key={item.Id} class="flex flex-row">
      <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-1">
        <div class="flex flex-col w-10 h-10 justify-center items-center mr-2">
          <img
            alt="profil"
            src={item.Type === "folder" ? folder_logo : file_logo}
            class="mx-auto object-cover rounded-md h-14 w-14"
          />
        </div>

        <div class="flex-1 pl-1">
          <div class="font-medium dark:text-white text-sm">
            {item.Name}
          </div>
        </div>

        <div class="pl-1">
          <a href='#' onClick={() => onRename(item.Id)}>Rename</a>
        </div>

        <div class="pl-1">
          <a href='#' onClick={() => onDelete(item.Id)}>X</a>
        </div>

      </div>
    </li>
  </a>);
}