import moment from 'moment';

export function GetTimeFrames() {




 
    

    var weeks = [];



    var currentWeekIndex = moment().week();
    
    
    var nextWeek = moment().week(currentWeekIndex+1);
    weeks.push({ Name: "Backlog", StartDate: nextWeek.startOf("isoWeek"), EndDate: moment("01.01.2050", "DD.MM.YYYY")});
    
    
    for(var curr = currentWeekIndex; curr >= 1; curr--)
    {
        var currentWeek = moment().week(curr);

        var startOfWeek = currentWeek.startOf("isoWeek");

        var endOfWeek = moment(startOfWeek);
        endOfWeek.add(7, "days");

        weeks.push({ 
            Name: "Week - "+curr, 
            StartDate: startOfWeek, 
            EndDate: endOfWeek
        });
    }




   
    
    
    
    
    //let week1 = moment().week(1);
    //console.log("Moment with Week of 1 is:", week1.toString())
      
    //let week40 = moment().week(40);
    //console.log("Moment with Week of 40 is:", week40.toString())

    /*moment().weeksInYear()
    moment().week(Number); 

    startDate = moment([year, month])
    firstDay = moment(startDate).startOf('month')
    endDay = moment(startDate).endOf('month')
    monthRange = moment.range(firstDay, endDay)

    weeks = []
    monthRange.by('days', (moment)->
        if moment.week() not in weeks
            weeks.push(moment.week())
    )

    calendar = []
    firstWeekDay = moment().week(week).day(1)
    lastWeekDay = moment().week(week).day(7)
     weekRange = moment.range(firstWeekDay, lastWeekDay)
        
    # Add to the calendar
    calendar.push(weekRange)*/
  
    return weeks;
}