import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

import { EditMeta } from "../../Api/IterationClient";

export default function ItemPopup({ open, setOpen, itemMeta, updateList  }) {
 
  const deadline = new Date(itemMeta.Deadline).toISOString().split('T')[0];

  const handleChange = (e) => {

    var newValue = e.target.value; 
    if(e.target.id === "deadline")
      setMeta({ ...meta, Deadline: newValue });
   
    if(e.target.id === "name")
      setMeta({ ...meta, Name: newValue });
    
    if(e.target.id === "status")
      setMeta({ ...meta, Status: parseInt(newValue) });
    
  };

  const [meta, setMeta] = useState(itemMeta);
  const [loading, setLoading] = useState(false);

  const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);
        const response = EditMeta(meta).then(response => {
            console.log(response.data);
            updateList(meta);
            setLoading(false);
            setOpen(false);
        });
    };


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
   
                <div class="mb-6">
                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                    
                    <input type="text" 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        id="name" name="name"
                        onChange={handleChange}
                        defaultValue={meta.Name} />
                </div>


                <div class="mb-6">
                    <label for="deadline" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Deadline</label>
                    <input type="date" 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        id="deadline" name="deadline"
                        onChange={handleChange}
                        defaultValue={deadline} />
                </div>


                <div class="mb-6">
                    <label for="status" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status</label>
                    <select id="status" name="status" 
                    defaultValue={meta && meta.Status} onChange={handleChange}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option value={0}>NotStarted</option>
                      <option value={1}>Active</option>
                      <option value={2}>Pending</option>
                      <option value={3}>Closed</option>
                    </select>
                </div>


           




                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleSubmit}>
                    {loading ? <>Loading...</>:<>Save</>}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}