
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';



import React, { useState, useEffect } from 'react';
import { useMatch, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import DottedSpinner from "../Core/DottedSpinner/DottedSpinner";


import useGroupGet from "./Hooks/useGroupGet";


import { EditGroup } from "./Api/ResourceGroupClient";

import { useParams } from "react-router-dom";


import TagView from "../Core/TagView/TagView";
import LoadingControl from '../Core/LoadingControl/LoadingControl';


export default  function ResourceGroupEdit() {

  const navigate = useNavigate();
  const { groupId } = useParams();
  const { group, setGroup, loading } = useGroupGet(groupId);
  const [saving, setSaving ] = useState(false);
  const setTags = (tags) => {
    setGroup({
        ...group,
        Tags: tags
      });
  };
  
  const handleOnBlur = e => {
    let elementId = e.target.id;
    let attributeValue = e.target.value.toString().trim();
    if(elementId === "name") setGroup({ ...group, Name: attributeValue });
  }

  const handleSubmit = event => {
    event.preventDefault();
    handleSave(group);
  };

  const handleDelete =()=>{
    var result = window.confirm("Please confirm to delete.");
    if(!result)
      return;

    var groupEx = { ...group, RecordState: 1 };
    handleSave(groupEx);
  };

  const handleSave = (groupEx) =>{
    setSaving(true);
    const response = EditGroup(groupEx).then(response => {
      console.log(response.data);
      setSaving(false);
      navigate("/groups");
    });
  };




  return (
    <Box sx={{ width: '500px' }}>
      {!group ? <><LoadingControl /></> :
      <Stack component="form" onSubmit={handleSubmit} spacing={2}>

          <TextField
            required
            id="name"
            name="name"
            onBlur={handleOnBlur}
            defaultValue={group && group.Name}
            label="Group Name"
            fullWidth />

          <TagView tags={group.Tags} setTags={setTags} />

          <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
              {saving ? <DottedSpinner /> : <>Save</>}
          </Button>

          <Button
              onClick={handleDelete}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
              <>Delete</>
          </Button>

      </Stack>}

    </Box>
  );
}