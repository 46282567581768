import * as React from "react";

import { useCallback, useState, useEffect } from "react";

import useProductEvents from "./Hooks/useProductEvents";
import TableView from "../../Components/TableView";


import LoadingControl from "../../../../components/Core/LoadingControl/LoadingControl";

export default function FunnelsWeekly() {
  const { data, loading } = useProductEvents();
 


  var columns = Object.getOwnPropertyNames(data)
  var rows = Object.getOwnPropertyNames(data[columns[0]]);  
  console.log(data);
  
  const appNames = rows
  .map(row => data[columns[1]][row])
  .filter(function(item, pos, self) {
    return self.indexOf(item) === pos;
    });

  console.log(data);

  return (
    <div className="m-5">
      <div>
        <label for="countries"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Select an app
        </label>
        <select id="countries"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option selected>Choose an app</option>
          {appNames.map(appName => (<option value={appName}>{appName}</option>))}
        </select>


        <h3>Funnels Weekly</h3>
        {loading ? (
          <><LoadingControl /></>
        ) : (
          <>
            <TableView data={data} />
          </>
        )}
      </div>
    </div>
  );
}
