import { useMatch, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import useFunnelGet from "./Hooks/useFunnelGet";

import { useHistory, useLocation } from "react-router-dom";
import { EditFunnel } from "./Api/FunnelClient";
import toast from "react-hot-toast";
import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";

export default function FunnelEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  var template =
    location.pathname.indexOf("edit") > 0
      ? "/project/:projectId/funnel/:funnelId/*"
      : "/project/:projectId/funnels/create";

  const match = useMatch(template);
  var projectId = match && match.params && match.params.projectId;
  var funnelId = match && match.params && match.params.funnelId;

  const { meta, setMeta, loading } = useFunnelGet(projectId, funnelId);
  const [saving, setSaving] = useState(false);

  const handleOnBlur = (e) => {
    let elementName = e.target.name;
    let elementId = e.target.id;
    let attributeValue = e.target.value.toString().trim();
    if (elementId === "name") setMeta({ ...meta, Name: attributeValue });
    //if(elementId === "appkey") setMeta({ ...meta, AppKey: attributeValue });
    //if(elementName === "status") setMeta({ ...meta, Status: attributeValue });
  };

  const handleDelete = () => {
    var result = window.confirm("Please confirm to delete?");
    if (!result) return;

    var metaEx = { ...meta, RecordState: 1 };

    setSaving(true);
    const response = EditFunnel(metaEx).then((response) => {
      setSaving(false);
      navigate(`/project/${meta.ProjectId}/funnels`);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const response = EditFunnel(meta).then((response) => {
      console.log(response.data);
      setSaving(false);
      toast.success("Saved");
      //navigate("/projects");
    });
  };

  return (
    <div className="m-5">
      {loading ? (
        <>
          <LoadingControl />
        </>
      ) : (
        <>
          <form component="form" className="m-2">
            <div class="mb-6">
              <label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                onBlur={handleOnBlur}
                defaultValue={meta && meta.Name}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Name"
                required
              />
            </div>

            <div className="flex flex-col">
              <button
                onClick={handleSubmit}
                type="submit"
                className="bg-white hover:bg-gray-100 text-gray-800 py-2 px-4 border border-gray-400 rounded shadow"
              >
                {saving ? <>Saving...</> : <>Save</>}
              </button>
            </div>
          </form>

          <button
            onClick={handleDelete}
            className="bg-white hover:bg-gray-100 text-gray-800 py-2 px-4 border border-gray-400 rounded shadow"
          >
            Delete
          </button>
        </>
      )}
    </div>
  );
}
