import React, { useState, useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import useExperimentGet from "./Hooks/useExperimentGet";

import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";

import MetricList from "./Components/MetricList/MetricList";
import MetricListEditor from "./Components/MetricListEditor/MetricListEditor";
import MetricTable from "./Components/MetricTable/MetricTable";
import useData from "./Hooks/useData";
export default function ExperimentEdit() {
  //---
  const match = useMatch("/project/:projectId/experiment/:experimentId");
  var projectId = match && match.params && match.params.projectId;
  var experimentId = match && match.params && match.params.experimentId;

  //===========
  const { experiment, setExperiment, loading } = useExperimentGet(
    projectId,
    experimentId
  );

  const { data } = useData(experiment?.AppKey, experiment?.Platform);

  //===========

  return (
    <div className="m-5 w-full">
      {loading ? (
        <>
          <LoadingControl />
        </>
      ) : (
        <>
          <MetricTable experiment={experiment} data={data} />
        </>
      )}
    </div>
  );
}
